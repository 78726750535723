import {TableColumnsSetting} from "@/interfaces/table-setting";
import {RentalClientModel} from "@/models/rentals/rental-client-model";

export class RentalClientsTableSettings {
  public static getColumnsSetting(): TableColumnsSetting<keyof RentalClientModel> {
    return [
      {
        name: "btnCustom",
        customComponent: true,
        unsortable: true,
      },
      {
        name: "numeroBp",
        text: 'BP',
        filter: {
          type: "input"
        }
      },
      {
        name: "codiceFiscale",
        text: 'Codice Fiscale',
        filter: {
          type: "input"
        }
      },
      {
        name: "piva",
        text: 'P. IVA',
        filter: {
          type: "input"
        }
      },
      {
        name: "localita",
        text: 'Località',
        filter: {
          type: "input"
        }
      },
      {
        name: "nome1",
        text: 'Nome 1',
        filter: {
          type: "input"
        }
      },
      {
        name: "nome2",
        text: 'Nome 2',
        filter: {
          type: "input"
        }
      },
      {
        name: "nome3",
        text: 'Nome 3',
        filter: {
          type: "input"
        }
      }
    ];
  }
}
