import {FormSetting} from "@/form-settings/form-setting";
import {ControlBase} from "@components/dynamic-form/control-base";
import {ControlType} from "@components/dynamic-form/control-type";
import {DateControl} from "@components/dynamic-form/date-control";

export class RentalInsuranceFormSetting extends FormSetting {
  private static isNew: boolean;
  private static rows: ControlBase[] = [];

  public static setIsNew(isNew: boolean) {
    this.isNew = isNew;

    return this;
  }

  public static getMeta(): Array<any> {
    this.rows = []

    if (!this.isNew) {
      this.addRowId();
    }

    this.addRowCompagnia();
    this.addRowDataInizio();
    this.addRowDataFine();
    this.addRowNumeroPolizza();
    this.addRowIfk();
    this.addRowPai();
    this.addRowRc();
    this.addRowStato();

    return [
      {
        "rows": this.rows,
        "order": 1
      },
    ]
  }

  private static addRowId() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'id';
    inputsComponent.disabled = true;
    inputsComponent.hidden = !this.isNew;
    inputsComponent.controlType = ControlType.textInput
    inputsComponent.label = 'ID';
    inputsComponent.class = "w-4/12";

    this.rows.push(inputsComponent);
  }

  private static addRowCompagnia() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'compagnia';
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.label = 'Compagnia';
    inputsComponent.class = "w-4/12";

    this.rows.push(inputsComponent);
  }

  private static addRowDataFine() {
    const inputsComponent = {} as DateControl;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'dataFine';
    inputsComponent.controlType = ControlType.date;
    inputsComponent.label = 'Data Fine';
    inputsComponent.class = "w-4/12";

    this.rows.push(inputsComponent);
  }

  private static addRowDataInizio() {
    const inputsComponent = {} as DateControl;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'dataInizio';
    inputsComponent.controlType = ControlType.date;
    inputsComponent.label = 'Data Inizio';
    inputsComponent.class = "w-4/12";

    this.rows.push(inputsComponent);
  }

  private static addRowIfk() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'ifk';
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.label = 'IFK';
    inputsComponent.class = "w-4/12";

    this.rows.push(inputsComponent);
  }

  private static addRowNumeroPolizza() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'numeroPolizza';
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.label = 'Numero Polizza';
    inputsComponent.class = "w-4/12";

    this.rows.push(inputsComponent);
  }

  private static addRowPai() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'pai';
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.label = 'PAI';
    inputsComponent.class = "w-4/12";

    this.rows.push(inputsComponent);
  }

  private static addRowRc() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'rc';
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.label = 'RC';
    inputsComponent.class = "w-4/12";

    this.rows.push(inputsComponent);
  }

  private static addRowStato() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'stato';
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.disabled = true;
    inputsComponent.label = 'Stato';
    inputsComponent.class = "w-4/12";

    this.rows.push(inputsComponent);
  }
}
