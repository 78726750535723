import {Component, OnInit} from '@angular/core';
import {UserForm} from "@pages/admin/users/details/form-setting/user-form";
import {UserService} from "@services/admin/user.service";
import {LoggerService} from "@services/logger.service";
import {Router} from "@angular/router";
import {Registry} from "@/registry.service";
import {User} from "@/models/user.model";
import {DynamicFormComponent} from "@components/dynamic-form/dynamic-form.component";
import {faUser} from "@fortawesome/free-solid-svg-icons";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-user-form-new',
  templateUrl: '../../../../../components/dynamic-form/dynamic-form.component.html',
  styleUrls: ['../../../../../components/dynamic-form/dynamic-form.component.scss']
})
export class UserFormNewComponent extends DynamicFormComponent implements OnInit {
  faIcon = faUser;

  constructor(protected router: Router, protected userService: UserService, protected toastr: ToastrService) {
    super(toastr, userService)

    this.tabs = [
      {
        'title': 'Dettaglio Utente',
        'meta': UserForm.formNewMeta().sort((a: any, b: any) => a.order - b.order)
      },
      {
        'title': 'Aziende',
        'meta': []
      },
      {
        'title': ' Ruoli',
        'meta': []
      }
    ]
    this.submitButtonVisible = true
    this.submitTopButton = false
    this.primaryButton = 'Crea Utente'

    this.userService.onUpdate().subscribe(() => {
      this.ngOnInit();
    });
  }

  ngOnInit() {
    const format = {
      add: 'Aggiungi',
      remove: 'Rimuovi',
      all: 'Tutte',
      none: 'Nessuna',
      direction: 'left-to-right',
      draggable: false,
      locale: undefined
    }
    this.tabs[0] = {
      'title': 'Dettaglio Utente',
      'meta': UserForm.formNewMeta().sort((a: any, b: any) => a.order - b.order)
    }
    this.userService.getCompanyCodes().subscribe(company => {
      this.tabs[1] = {
        'title': 'Aziende',
        'meta': [
          {
            "rows": [
              {
                "key": "companyCodes",
                "controlType": "dual-list",
                "label": "Aziende",
                "class": "w-full",
                "source": company,
                "format": format,
                "destination": this.data.companyCodes
              },
            ],
            "order": 1
          }
        ]
      }
    });
    this.userService.getRoles().subscribe(roles => {
      this.tabs[2] = {
        'title': ' Ruoli',
        'meta': [
          {
            "rows": [
              {
                "key": "roles",
                "controlType": "dual-list",
                "label": "Ruoli",
                "class": "w-full",
                "source": roles,
                "format": format,
                "destination": this.data.roles
              },
            ],
            "order": 1
          }
        ]
      }
    })

    this.form.get('username').valueChanges.subscribe((event) => {
      this.form.get('username').setValue(event.toLowerCase(), {emitEvent: false});
    })
  }

  secondButtonAction() {
    this.router.navigate(['admin/users']);
  }

  onSubmit() {
    this.validPasswords()

    const registry = Registry.getInstance()
    const user = <User>this.form.getRawValue()
    LoggerService.log(user)
    user.roles = registry.get('roles')
    user.companyCodes = registry.get('companyCodes')

    this.userService.createUser(user).subscribe({
      next: () => {
        const username = user.username
        this.router.navigate([`admin/users/${username}`]);

        this.toastr.success('Utente creato correttamente!');
      },
      error: (error) => {
        LoggerService.error('createUser failed!');
        LoggerService.error(error.error);

        if (error.error.message === "user.exists") {
          this.toastr.error(`Utente già presente! Cambiare username!`);
        } else {
          this.toastr.error(`Errore nella creazione di un nuovo utente.`);
        }

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
      complete: () => {
        LoggerService.log('Request done')

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
    })
  }

  setupMeta(): void {
  }

  protected validPasswords() {
    const password = this.form.controls.password.value ?? '';
    const confirmPassword = this.form.controls.confirmPassword.value ?? '';

    if ((password.length !== 0 || confirmPassword.length !== 0) && password !== confirmPassword) {
      LoggerService.error('Error validation')
      this.form.controls['password'].setErrors({
        'additionalValidator': true,
        message: 'Le password devono essere uguali'
      });
      this.form.controls['confirmPassword'].setErrors({
        'additionalValidator': true,
        message: 'Le password devono essere uguali'
      });
    }
  }

}
