import {ApiService} from "@services/api.service";
import {TableDataApiParams} from "@services/practices.service";
import {Observable, throwError} from "rxjs";
import {TablePaginationRespModel} from "@/models/table-pagination-resp.model";
import {catchError, tap} from "rxjs/operators";
import {RentalClientModel} from "@/models/rentals/rental-client-model";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class RentalClientsService extends ApiService {
  getDataForTable(params?: TableDataApiParams): Observable<TablePaginationRespModel<RentalClientModel>> {
    return new Observable<TablePaginationRespModel<RentalClientModel>>(observer => {
      this.http.get<TablePaginationRespModel<RentalClientModel>>(
        this.baseUrl + '/rental-practices/customer/page',
        {params: {...params}})
        .pipe(
          tap(response => {
            observer.next(response);
            observer.complete();
          }),
          catchError(error => {
            observer.error(error);

            return throwError(error);
          })
        ).subscribe();
    });
  }
}
