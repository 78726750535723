import {Component, inject, Input, OnInit} from '@angular/core';
import {DataSourceTable} from "@components/new-table/data-sources/data-source-table";
import {ToastrService} from "ngx-toastr";
import {MatDialog} from "@angular/material/dialog";
import {Subject} from "rxjs";
import {ActivatedRoute, Params} from "@angular/router";
import {RentalMovementTableSettings} from "@/table-settings/rentals/rental-movement-table-settings";
import {RentalMovementService} from "@services/data-source-tables/rentals/rental-movement.service";
import {Registry} from "@/registry.service";
import {
  DialogFormRentalMovementComponent
} from "@pages/rental-management/rent-practice-detail/rental-movements-list/dialog-form-rental-movement/dialog-form-rental-movement.component";
import {RentalMovementModel} from "@/models/rentals/rental-movement-model";
import {ButtonInterface} from "@components/button/button-interface";
import {RentalClientsService} from "@services/data-source-tables/rentals/rental-clients.service";
import {TableDataApiParams} from "@services/practices.service";

@Component({
  selector: 'app-rental-movements-list',
  templateUrl: './rental-movements-list.component.html',
  styleUrls: ['./rental-movements-list.component.scss']
})
export class RentalMovementsListComponent implements OnInit {
  displayedColumns = RentalMovementTableSettings.getColumnsSetting()
  dataSource = new DataSourceTable(this.rentalMovementService);
  toastr = inject(ToastrService);
  dialog = inject(MatDialog);
  @Input() refreshRentalPracticeEvent$: Subject<any>;
  id: any;
  newButton: ButtonInterface;

  constructor(private rentalMovementService: RentalMovementService, private route: ActivatedRoute, protected rentalClientsService: RentalClientsService) {
    this.route.params.subscribe((params: Params) => {
      this.id = params['id'];
    });

    this.newButton = {
      content: 'Nuovo Noleggio',
      icon: 'details',
      showIcon: true,
      btnClick: ($event) => {
        let dialogRef = this.dialog.open(DialogFormRentalMovementComponent, {
          data: {
            movement: new RentalMovementModel(),
            rentalClientsService: this.rentalClientsService,
            practiceId: this.id,
            refreshRentalPracticeEvent$: this.refreshRentalPracticeEvent$,
            isNew: true
          },
          width: 'auto',
          panelClass: 'custom-modalbox',
        });

        Registry.getInstance().set(DialogFormRentalMovementComponent.name, dialogRef);
      }
    };
  }

  btnDetailsClickFunction(obj: RentalMovementModel) {
    let dialogRef = this.dialog.open(DialogFormRentalMovementComponent, {
      data: {
        movement: obj,
        rentalClientsService: this.rentalClientsService,
        practiceId: this.id,
        rentalMovementService: this.rentalMovementService,
        refreshRentalPracticeEvent$: this.refreshRentalPracticeEvent$
      },
      width: 'auto',
      panelClass: 'custom-modalbox',
    });

    Registry.getInstance().set('DialogFormRentalMovementComponent', dialogRef);
  }

  ngOnInit(): void {
    this.refreshRentalPracticeEvent$.subscribe(() => {
      const params = {id: this.id} as TableDataApiParams;

      this.dataSource.loadData(params);
    });
  }

}
