export class RentalMovementModel {
  public id: number;
  public stato: string;
  public canale: string;
  public tipoNoleggio: string;
  public categoriaNoleggio: string;
  public prezzo: number;
  public carburanteConsegna: string;
  public carburanteRientro: string;
  public dataConsegna: Date;
  public dataRientro: Date;
  public kmConsegna: number;
  public kmRientro: number;
  public dataUltimaRilevazioneKm: Date;
  public kmRilevati: number;
  public bpSocieta: string;
  public societa: string;
  public bpUtilizzatore: string;
  public denominazioneUtilizzatore: string;
  public codiceFiscaleUtilizzatore: string;
  public pivaUtilizzatore: string;
  public patenteUtilizzatore: string;
  public patenteRilasciataDaUtilizzatore: string;
  public viaUtilizzatore: string;
  public cittaUtilizzatore: string;
  public dataContratto: Date;
  public dataInizioNoleggio: Date;
  public dataFineNoleggio: Date;
  public luogoContratto: string;
}
