import {TableColumnsSetting} from "@/interfaces/table-setting";
import {CrossF7DeletedModel} from "@/models/imports/cross-f7-deleted-model";

export class CrossF7DeletedSettingsTable {
  public static getColumnsSetting(): TableColumnsSetting<keyof CrossF7DeletedModel> {
    return [
      {
        name: "id",
        text: "id",
        filter: {
          type: "input",
        }
      },
      {
        name: "cancellato",
        text: "Cancellato",
        filter: {
          type: "input",
        }
      },
      {
        name: "descrizioneModello",
        text: "Descrizione Modello",
        filter: {
          type: "input",
        }
      },
      {
        name: "importId",
        text: "Import Id",
        filter: {
          type: "input",
        }
      },
      {
        name: "numeroOrdine",
        text: "Numero Ordine",
        filter: {
          type: "input",
        }
      },
      {
        name: "numeroPratica",
        text: "Numero Pratica",
        filter: {
          type: "input",
        }
      },
      {
        name: "statoOrdine",
        text: "Stato Ordine",
        filter: {
          type: "input",
        }
      },
      {
        name: "statoOrdineVgi",
        text: "Stato Ordine VGI",
        filter: {
          type: "input",
        }
      },
      {
        name: "statoPratica",
        text: "Stato Pratica",
        filter: {
          type: "input",
        }
      },
      {
        name: "createdAt",
        type: "datetime",
        text: "creato il",
        filter: {
          type: 'dateRange'
        }
      },
      {
        name: "updatedAt",
        type: "datetime",
        text: "modificato il",
        filter: {
          type: 'dateRange'
        }
      }
    ]
  }
}
