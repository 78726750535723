import {TableColumnsSetting} from "@/interfaces/table-setting";
import {BuyerPracticeInvoiceModel} from "@/models/buyer-practice-invoice-model";

export class BuyerPracticeInvoiceSettingsTable {
  public static getColumnsSetting(): TableColumnsSetting<keyof BuyerPracticeInvoiceModel> {
    return [
      {
        name: "tipoDocumento",
        text: "Tipo Documento"
      },
      {
        name: "descrizione",
        text: "Descrizione"
      },
      {
        name: "descrizioneContoCoge",
        text: "Descrizione Conto Coge"
      },
      {
        name: "genProdPostingGroup",
        text: "genProdPostingGroup"
      },
      {
        name: "importo",
        text: "Importo",
        type: "currency"
      },
      {
        name: "importoInclusoIva",
        text: "Importo Iva Inc.",
        type: "currency"
      },
      {
        name: "motivoCode",
        text: "Codice Motivo"
      },
      {
        name: "numLinea",
        text: "Numero Linea"
      },
      {
        name: "numeroDocumento",
        text: "Numero Documento"
      },
    ];
  }
}
