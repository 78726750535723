<h1 class="text-dark text-xl" mat-dialog-title>Seleziona Cliente</h1>
<div class="w-full" mat-dialog-content>
  <app-new-table
    (btnCustomClickEvent)="btnSelectClientClickFunction($event)"
    [btnCustomShowIcon]="false"
    [btnCustomText]="'Seleziona'"
    [dataSource]="dataSource"
    [displayedColumns]="displayedColumns"
    [initialSort]="{active: 'numeroBp',direction: 'asc'}"
  ></app-new-table>
</div>
