import {Component, inject} from '@angular/core';
import {PracticesService} from "@services/practices.service";
import {LoggerService} from "@services/logger.service";
import {TablePracticeModel} from "@/models/tables/table-practices.model";
import {DialogPopUpComponent} from "@components/dialog-pop-up/dialog-pop-up.component";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";
import {Subject} from "rxjs";
import {PracticesDataSource} from "@components/new-table/data-sources/practices.dataSource";
import {Router} from "@angular/router";
import {PracticeTableSettings} from "@/table-settings/practices/practice-table.settings";
import {TableColumnsSetting} from '@/interfaces/table-setting';
import {UserService} from "@services/admin/user.service";
import {Role} from "@/models/role";

@Component({
  selector: 'app-practices',
  templateUrl: './practices.component.html',
  styleUrls: ['./practices.component.scss']
})
export class PracticesComponent {
  displayedColumns: TableColumnsSetting<any>
  dataSource = new PracticesDataSource(this.practicesService);
  toastr = inject(ToastrService);
  dialog = inject(MatDialog);
  reloadTrigger$ = new Subject<any>();

  constructor(private practicesService: PracticesService, private userService: UserService, private router: Router) {
    const roles = userService.getUserRoles()
    const canDeletePractice = roles.includes(Role.Admin) || roles.includes(Role.Backoffice)

    this.displayedColumns = PracticeTableSettings.setRoleDeleteButton(canDeletePractice).getColumnsSetting(this.practicesService);
  }

  btnDeleteFunction({id: practiceId}: TablePracticeModel) {
    let dialogRef = this.dialog.open(DialogPopUpComponent, {
      width: 'auto',
      panelClass: 'custom-modalbox',
    });

    this.setupPopup(dialogRef)

    dialogRef.afterClosed().subscribe(deletePractice => {
      if (deletePractice) {
        this.practicesService.deletePractice(practiceId).subscribe({
          next: () => {
            this.reloadTrigger$.next(1);
            this.toastr.success('Pratica cancellata correttamente!');
          },
          error: (error) => {
            this.toastr.error(`Errore durante la cancellazione della pratica.`);

            LoggerService.error('deleteUser failed!')
            LoggerService.error(error);
          },
          complete: () => {
            LoggerService.log('Request done')
          },
        });
      }
    });
  }

  btnDetailsClickFunction({id}: TablePracticeModel) {
    this.router.navigate([`/practices-management/practices/details/${id}`])
  }

  private setupPopup(dialogRef: MatDialogRef<DialogPopUpComponent>) {
    dialogRef.componentInstance.title = 'Confermi di voler cancellare questa pratica?'
    dialogRef.componentInstance.content = "Attenzione una volta cancellata la pratica non sarà più possibile recuperarla."
    dialogRef.componentInstance.firstCTA = "Si cancella"
    dialogRef.componentInstance.secondCTA = "Annulla operazione"
    dialogRef.componentInstance.closeResultFirst = true
    dialogRef.componentInstance.closeResultSecond = false
  }
}
