import {TableColumnsSetting} from "@/interfaces/table-setting";
import {RentalInsuranceModel} from "@/models/rentals/rental-insurance-model";

export class RentalInsuranceTableSettings {

  public static getColumnsSetting(): TableColumnsSetting<keyof RentalInsuranceModel> {
    return [
      {
        name: "btnDetails",
        customComponent: true,
        unsortable: true,
      },
      {
        name: "id",
        text: 'ID'
      },
      {
        name: "compagnia",
        text: 'Compagnia'
      },
      {
        name: "numeroPolizza",
        text: 'Numero Polizza'
      },
      {
        name: "dataInizio",
        text: 'Data Inizio',
        type: "date"
      },
      {
        name: "dataFine",
        text: 'Data Fine',
        type: "date"
      },
      {
        name: "ifk",
        text: 'IFK'
      },
      {
        name: "pai",
        text: 'PAI'
      },
      {
        name: "rc",
        text: 'RC'
      },
      {
        name: "stato",
        text: 'Stato'
      }
    ];
  }
}
