import {MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from "@angular/material-moment-adapter";
import {Inject, Optional} from "@angular/core";

export class CustomDateAdapter extends MomentDateAdapter {
  constructor(
    @Inject(MAT_DATE_LOCALE) dateLocale: string,
    @Optional() @Inject(MAT_MOMENT_DATE_ADAPTER_OPTIONS) options?: any
  ) {
    super(dateLocale, options);
  }

  override getFirstDayOfWeek(): number {
    return 1;
  }
}
