export const GLOBAL_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY', // Formato accettato nell'input
  },
  display: {
    dateInput: 'DD/MM/YYYY',        // Come appare nell'input
    monthYearLabel: 'MMM YYYY',     // Etichetta del mese e anno
    dateA11yLabel: 'LL',            // Per l'accessibilità
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const YEAR_ONLY_FORMATS = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',           // Formato mostrato nell'input
    monthYearLabel: 'YYYY',      // Etichetta mostrata nel selettore
    dateA11yLabel: 'YYYY',       // Accessibilità
    monthYearA11yLabel: 'YYYY',  // Accessibilità del selettore anno
  },
};

export const MONTH_ONLY_FORMATS = {
  parse: {
    dateInput: 'MM',
  },
  display: {
    dateInput: 'MM',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const YEAR_MONTH_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY', // Formato accettato dall'input
  },
  display: {
    dateInput: 'MM/YYYY',           // Formato mostrato nell'input
    monthYearLabel: 'MMM YYYY',     // Etichetta nel selettore mese/anno
    dateA11yLabel: 'MMMM YYYY',     // Accessibilità per l'input
    monthYearA11yLabel: 'MMMM YYYY' // Accessibilità per il selettore
  },
};
