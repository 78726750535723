import {Component, OnInit} from '@angular/core';
import {LoggerService} from "@services/logger.service";
import {ActivatedRoute, Params} from "@angular/router";
import {PracticesService} from "@services/practices.service";
import {PracticeModel} from "@/models/practice.model";
import {Tab} from "@/interfaces/tab";
import {LocalDataSource} from "ng2-smart-table";
import {ExchangeSettings} from "@/table-settings/exchange-settings";
import {saveAs} from 'file-saver';
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {
  DialogNewExchangeComponent
} from "@pages/practices-management/practices/practice-details/dialogs/dialog-new-exchange/dialog-new-exchange.component";
import {Registry} from "@/registry.service";
import {UsedVehicleModel} from "@/models/usedVehicleModel";
import {DocumentsPracticeSettings} from "@/table-settings/documents-practice-settings";
import {PracticeDocument} from "@/models/practice-document.model";
import {
  DialogUploadDocumentComponent
} from "@pages/practices-management/practices/practice-details/dialogs/dialog-upload-document/dialog-upload-document.component";
import {getCurrentUserRoles} from "@utils-functions";
import {Role} from "@/models/role";
import {
  faBoxes,
  faCalculator,
  faCar,
  faChartBar,
  faCheckCircle,
  faCircleInfo,
  faFile,
  faFilePdf,
  faInbox,
  faList,
  faRightLeft,
  IconDefinition
} from "@fortawesome/free-solid-svg-icons";
import {ChecklistDocumentSettings} from "@/table-settings/checklist-document-settings";
import {AccessoriesSettings} from "@/table-settings/accessories-settings";
import {
  DialogNewAccessoryPracticeComponent
} from "@pages/practices-management/practices/practice-details/dialogs/dialog-new-accessory/dialog-new-accessory-practice.component";
import {PracticeAccessoryModel} from "@/models/practice-accessory-model";
import {
  DialogDuplicatePracticeComponent
} from "@pages/practices-management/practices/practice-details/dialogs/dialog-duplicate-practice/dialog-duplicate-practice.component";
import {PracticeCommunicationModel} from "@/models/practice-communication-model";
import {CommunicationPracticeSettings} from "@/table-settings/communication-practice-settings";
import {
  DialogNewCommunicationPracticeComponent
} from "@pages/practices-management/practices/practice-details/dialogs/dialog-new-communication/dialog-new-communication-practice.component";
import {EquipmentSettings} from "@/table-settings/equipment-settings";
import {faPuzzlePiece} from "@fortawesome/free-solid-svg-icons/faPuzzlePiece";
import {
  DialogUploadMultipleDocumentComponent
} from "@pages/practices-management/practices/practice-details/dialogs/dialog-upload-multiple-document/dialog-upload-multiple-document.component";
import {RentalPracticeModel} from "@/models/rental-practice.model";
import {
  DialogRentablePracticeNewComponent
} from "@pages/practices-management/practices/practice-details/dialogs/dialog-rentable-practice-new/dialog-rentable-practice-new.component";
import {
  DetailPracticeFormComponent
} from "@pages/practices-management/practices/practice-details/detail-practice-form/detail-practice-form.component";

@Component({
  selector: 'app-practice-details',
  templateUrl: './practice-details.component.html',
  styleUrls: ['./practice-details.component.scss']
})
export class PracticeDetailsComponent implements OnInit {
  data: PracticeModel
  downloadPdfText: string = 'Scarica Frontespizio';
  tabs: Array<Tab> = []
  sourceExchangesTable: LocalDataSource = new LocalDataSource([])
  sourceDocumentsTable: LocalDataSource = new LocalDataSource([])
  sourceAccessoriesTable: LocalDataSource = new LocalDataSource([])
  sourceChecklistDocumentsTable: LocalDataSource = new LocalDataSource([])
  sourceCommunicationsTable: LocalDataSource = new LocalDataSource([])
  sourceEquipmentsTable: LocalDataSource = new LocalDataSource([])
  //Setting table
  settingsExchange = ExchangeSettings.gridSettings()
  settingsDocuments = DocumentsPracticeSettings.gridSettings()
  settingsChecklistDocuments = ChecklistDocumentSettings.gridSettings()
  settingsAccessories = AccessoriesSettings.gridSettings()
  settingsCommunications = CommunicationPracticeSettings.gridSettings()
  settingsEquipments = EquipmentSettings.gridSettings();
  newExchangeText: string = 'Aggiungi permuta'
  uploadDocumentText: string = 'Carica documento'
  uploadMultipleDocumentsText: string = 'Caricamento multiplo documenti'
  completeDocumentText: string = 'Documenti completi'
  modelSelected: boolean = false;
  matchingVehicle: boolean = false;
  protected readonly faFile = faFile;
  protected readonly iconCompleteDocument: IconDefinition = faCheckCircle
  protected readonly iconDownloadPdf: IconDefinition = faFilePdf
  protected readonly faPuzzlePiece = faPuzzlePiece;
  private practiceId: number;
  private dialogDocuments: MatDialogRef<DialogUploadDocumentComponent, any>
  private dialogExchange: MatDialogRef<DialogNewExchangeComponent, any>
  private dialogAccessory: MatDialogRef<DialogNewAccessoryPracticeComponent, any>
  private dialogCommunication: MatDialogRef<DialogNewCommunicationPracticeComponent, any>
  private dialogDuplicatePractice: MatDialogRef<DialogDuplicatePracticeComponent, any>
  private dialogDocumentsMultiple: MatDialogRef<DialogUploadMultipleDocumentComponent, any>;
  private dialogRentablePractice: MatDialogRef<DialogRentablePracticeNewComponent, any>;


  constructor(private route: ActivatedRoute, protected practiceService: PracticesService, private dialog: MatDialog) {
    this.route.params.subscribe((params: Params) => {
      this.practiceId = params['practiceId'];
    });
    this.data = {} as PracticeModel;

    Registry.getInstance().set('PracticeDetailsComponent', this)
  }

  async ngOnInit() {
    await this.refreshPractice()

    if (!this.data.cliente && !this.matchingVehicle) {
      this.tabs = [
        {
          'title': 'Dettaglio veicolo',
          'faIcon': faCar
        }
      ]
    } else {
      this.setupMatchedTabs()
    }

    if (this.data.tipoPratica !== 'USATO') {
      await this.refreshAccessories()
      await this.refreshEquipments()
    }

    await this.refreshExchange()
    await this.refreshDocuments()
    await this.refreshChecklistDocument()
    await this.refreshCommunications()
  }

  async refreshPractice() {
    LoggerService.log('refreshPractice')

    await this.practiceService.getPractice(this.practiceId).then(practice => {
      LoggerService.log(practice, 'getPractice')

      this.data = <PracticeModel>practice

      if (practice.siglaModello) {
        this.modelSelected = true
      }

      this.setupMatchedTabs()
    });

    return 1;
  }

  async refreshExchange() {
    this.practiceService.getExchangesList(this.practiceId).subscribe({
      next: (exchanges) => {
        this.sourceExchangesTable.load(exchanges);
      },
      error: (error) => {
        LoggerService.error('getExchanges failed!');
        LoggerService.error(error);
      },
      complete: () => LoggerService.log('Request done'),
    });
  }

  downloadPracticePdf() {
    this.practiceService.downloadPraticaPdf(this.practiceId).subscribe((res) => {
      saveAs(res, 'Frontespizio-Pratica-' + this.data.tipoPratica + '-' + this.data.id + '.pdf');
    });
  }

  downloadSummaryCVPdf() {
    this.practiceService.downloadSummaryCVPdf(this.practiceId).subscribe((res) => {
      saveAs(res, 'Riepilogo-Pratica-' + this.data.id + '.pdf');
    });
  }

  downloadDetailVeicoloPdf() {
    this.practiceService.downloadDetailVehiclePdf(this.practiceId).subscribe((res) => {
      saveAs(res, 'DettaglioVeicolo-' + this.data.id + '.pdf');
    });
  }

  newExchange() {
    const exchange = new UsedVehicleModel();
    exchange.pratica = this.data;

    this.dialogExchange = this.dialog.open(DialogNewExchangeComponent, {
      data: exchange
    });
  }

  newAccessory() {
    const practiceAccessory = new PracticeAccessoryModel()
    practiceAccessory.pratica = this.data

    this.dialogAccessory = this.dialog.open(DialogNewAccessoryPracticeComponent, {
      data: practiceAccessory,
    });

    Registry.getInstance().set('DialogNewAccessoryPracticeComponent', this.dialogAccessory)
  }

  newCommunication() {
    const practiceCommunication = new PracticeCommunicationModel()
    practiceCommunication.pratica = this.data

    this.dialogCommunication = this.dialog.open(DialogNewCommunicationPracticeComponent, {
      data: practiceCommunication,
    });

    Registry.getInstance().set('DialogNewCommunicationPracticeComponent', this.dialogCommunication)
  }

  completeDocument() {
    this.practiceService.setCompleteDocuments(this.practiceId).subscribe(r =>
      this.data.statoDocumentiPratica = 'COMPLETO'
    );
  }

  uploadDocument() {
    const document = new PracticeDocument();
    document.praticaId = this.data.id

    this.dialogDocuments = this.dialog.open(DialogUploadDocumentComponent, {
      data: document,
    });
  }

  uploadMultipleDocument() {
    const document = new PracticeDocument();
    document.praticaId = this.data.id;

    this.dialogDocumentsMultiple = this.dialog.open(DialogUploadMultipleDocumentComponent, {
      data: document,
    });
  }

  closeDocumentDialog() {
    this.dialogDocuments.close()

    this.refreshDocuments()
    this.refreshChecklistDocument()
  }

  closeDocumentMultipleDialog() {
    this.dialogDocumentsMultiple.close()

    this.refreshDocuments()
    this.refreshChecklistDocument()
  }

  closeExchangeDialog() {
    this.dialogExchange.close()

    this.refreshExchange()
  }

  async refreshDocuments() {
    this.practiceService.getDocuments(this.practiceId).subscribe({
      next: (documents) => {
        this.sourceDocumentsTable.load(documents);
      },
      error: (error) => {
        LoggerService.error('getExchanges failed!');
        LoggerService.error(error);
      },
      complete: () => LoggerService.log('Request done'),
    });
  }

  async refreshAccessories() {
    LoggerService.log('refreshAccessories')
    this.practiceService.getAccessories(this.practiceId).subscribe({
      next: (accessories) => {
        this.sourceAccessoriesTable.load(accessories);
        let totCommissionsAccessory = 0;

        accessories.forEach((accessory) => {
          totCommissionsAccessory = totCommissionsAccessory + accessory.provvigione ?? 0
        })

        Registry.getInstance().set('totCommissionsAccessory', totCommissionsAccessory)
        Registry.getInstance().get(DetailPracticeFormComponent.name).updateProvvigioneAccessori()
      },
      error: (error) => {
        LoggerService.error('getAccessories failed!');
        LoggerService.error(error);
      },
      complete: () => LoggerService.log('Request done'),
    });
  }

  async refreshChecklistDocument() {
    LoggerService.log('Refresh Checklist Document')

    this.practiceService.getChecklistDocument(this.practiceId).subscribe({
      next: (checklist) => {
        this.sourceChecklistDocumentsTable.load(checklist);
      },
      error: (error) => {
        LoggerService.error('getChecklistDocument failed!');
        LoggerService.error(error);
      },
      complete: () => LoggerService.log('Request done'),
    });
  }

  async refreshEquipments() {
    this.practiceService.getEquipments(this.practiceId).subscribe({
      next: (equipments) => {
        this.sourceEquipmentsTable.load(equipments);
      },
      error: (error) => {
        LoggerService.error('getEquipments failed!');
        LoggerService.error(error);
      },
      complete: () => LoggerService.log('Request done'),
    });
  }

  async refreshCommunications() {
    this.practiceService.getCommunications(this.practiceId).subscribe({
      next: (communications) => {
        this.sourceCommunicationsTable.load(communications);
      },
      error: (error) => {
        LoggerService.error('getCommunications failed!');
        LoggerService.error(error);
      },
      complete: () => LoggerService.log('Request done'),
    });
  }

  duplicatePractice() {
    const practice = this.data;

    this.dialogDuplicatePractice = this.dialog.open(DialogDuplicatePracticeComponent, {
      data: practice,
    });

    Registry.getInstance().set('DialogDuplicatePracticeComponent', this.dialogDuplicatePractice)
  }

  isUserASubSgent() {
    return getCurrentUserRoles().includes(Role.Subagents)
      || getCurrentUserRoles().includes(Role.ResponsibleSubagents)
  }

  canSeeAnalysisFinancialData() {
    return getCurrentUserRoles().includes(Role.Admin) ||
      getCurrentUserRoles().includes(Role.Accounting) ||
      getCurrentUserRoles().includes(Role.ResponsibleSellers) ||
      getCurrentUserRoles().includes(Role.Backoffice)
  }

  practiceIsMatched() {
    return this.data.cliente;
  }

  matchedVehicle() {
    this.matchingVehicle = true
    this.setupMatchedTabs()
  }

  createRentablePractice() {
    const practice = this.data;
    const rentPractice = new RentalPracticeModel();
    rentPractice.modello = practice.descrizioneModello;
    rentPractice.marca = practice.marca;
    rentPractice.targa = practice.targa
    rentPractice.praticaId = practice.id;
    rentPractice.codicePratica = practice.codicePratica;
    rentPractice.categoriaVeicolo = practice.marca.categoriaVeicolo;
    rentPractice.valoreVeicolo = practice.prezzoImponibile;

    this.dialogRentablePractice = this.dialog.open(DialogRentablePracticeNewComponent, {
      data: rentPractice,
    });

    Registry.getInstance().set('DialogRentablePracticeNewComponent', this.dialogRentablePractice)
  }

  private setupMatchedTabs() {
    this.tabs = [
      {
        'title': 'Dettaglio pratica',
        'faIcon': faCircleInfo
      },
      {
        'title': 'Permute',
        'faIcon': faRightLeft
      },
      {
        'title': 'Documenti',
        'faIcon': faFile
      },
      {
        'title': 'Checklist documenti',
        'faIcon': faList
      },
      {
        'title': 'Comunicazioni subagenti',
        'faIcon': faInbox
      }
    ]

    if (this.data.tipoPratica === "NUOVO") {
      this.tabs = [
        {
          'title': 'Dettaglio pratica',
          'faIcon': faCircleInfo
        },
        {
          'title': 'Dettaglio veicolo',
          'faIcon': faCar
        },
        {
          'title': 'Permute',
          'faIcon': faRightLeft
        },
        {
          'title': 'Accessori',
          'faIcon': faBoxes
        },
        {
          'title': 'Documenti',
          'faIcon': faFile
        },
        {
          'title': 'Checklist documenti',
          'faIcon': faList
        },
      ]

      if (!this.isUserASubSgent()) {
        this.tabs.push({
          'title': 'Dati contabili',
          'faIcon': faCalculator
        });
      }

      if (this.canSeeAnalysisFinancialData()) {
        this.tabs.push({
          'title': 'Analisi dati finanziari',
          'faIcon': faChartBar
        });
      }
    }
  }
}
