import {Component, OnChanges, OnInit} from '@angular/core';
import {saveAs} from 'file-saver';
import {
  NewPracticeFormComponent
} from "@pages/practices-management/practices/new-practice/new-practice-form/new-practice-form.component";
import {PracticeModel} from "@/models/practice.model";
import {LoggerService} from "@services/logger.service";
import {Registry} from "@/registry.service";

@Component({
  selector: 'app-detail-practice-form',
  templateUrl: '../../../../../components/dynamic-form/dynamic-form.component.html',
  styleUrls: ['../../../../../components/dynamic-form/dynamic-form.component.scss']
})
export class DetailPracticeFormComponent extends NewPracticeFormComponent implements OnInit, OnChanges {
  ngOnInit(): void {
    super.ngOnInit()

    this.practiceTypeDisabled = true
    this.fieldArriveFromCross = true

    if (Object.values(this.data).length !== 0) {
      LoggerService.log('Update field after load data')

      this.setCommissionFields()
      this.refreshDataPracticeType(this.data.tipoPratica)
      this.securityDepositChanges(this.data.depositoCauzionale)

      if (this.data.marca && this.data.marca.id.length !== 0) {
        this.brandsChanges(this.data.marca.id)
      }

      if (this.data.tipoCliente && this.data.tipoCliente.length !== 0) {
        this.typeClientChanges(this.data.tipoCliente)
      }

      if (this.data.tipoPagamento && this.data.tipoPagamento.id.length !== 0) {
        this.typePaymentChanges(this.data.tipoPagamento)
      }

      this.updateSellerFields()
    }

    Registry.getInstance().set(DetailPracticeFormComponent.name, this)
  }

  public ngOnChanges() {
    super.ngOnChanges()

    this.updateClientType()
  }

  public onSubmit() {
    let practice = <PracticeModel>this.form.getRawValue()
    practice = this.setDefaultValues(practice);
    practice = this.setupVehicleData(practice)

    this.practiceService.savePractice(practice).subscribe({
      next: (response) => {
        this.toastr.success('Pratica salvata correttamente!');

        Registry.getInstance().get('PracticeDetailsComponent').data = <PracticeModel>response
      },
      error: (error) => {
        LoggerService.error('savePractice failed!');
        LoggerService.error(error);

        this.manageErrorMessages(error)

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
      complete: () => {
        LoggerService.log('Request done')

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
    })
  }

  protected thirdButtonAction() {
    this.practiceService.downloadPraticaPdf(this.data.id).subscribe((res) => {
      saveAs(res, 'Pratica' + this.data.id + '.pdf');
    });
  }

  private updateSellerFields() {
    const seller = this.data.venditore

    if (seller) {
      if (seller.segnalatore) {
        this.form.controls['noteSegnalatore'].enable();
      }

      LoggerService.log('AGENZIA SUBAGENTE', seller.agenziaSubagente)

      if (seller.agenziaSubagente != null && seller.users) {
        this.subAgentUsers = []

        for (const user of seller.users) {
          this.subAgentUsers.push({
            key: user.username,
            value: user.username,
          });
        }

        this.form.controls['utenteSubagente'].enable()
      }
    }
  }

  private setCommissionFields() {
    this.signalman = Boolean(this.data.segnalatore ?? false)
    this.sellerSelected = Boolean(this.data.codiceVenditore ?? false)

    this.setupMeta()
  }

  private setupVehicleData(practice: PracticeModel) {
    practice.id = this.data.id
    practice.codiceModello = practice.codiceModello ?? this.data.codiceModello;
    practice.targa = practice.targa ?? this.data.targa;
    practice.siglaModello = practice.siglaModello ?? this.data.siglaModello;
    practice.descrizioneModello = practice.descrizioneModello ?? this.data.descrizioneModello;
    practice.telaio = practice.telaio ?? this.data.telaio;
    practice.statoOrdineVgi = practice.statoOrdineVgi ?? this.data.statoOrdineVgi;
    practice.codiceColore = practice.codiceColore ?? this.data.codiceColore;
    practice.codiceColoreInterno = practice.codiceColoreInterno ?? this.data.codiceColoreInterno;
    practice.descrizioneColoreInterno = practice.descrizioneColoreInterno ?? this.data.descrizioneColoreInterno;
    practice.descrizioneColore = practice.descrizioneColore ?? this.data.descrizioneColore;

    return practice;
  }
}
