<h1 class="text-dark text-xl" mat-dialog-title>{{ isNew ? 'Nuovo noleggio' : 'Modifica noleggio' }}</h1>
<div class="w-full" mat-dialog-content>
  <div class="w-full flex pr-3">
    <app-button (btnClicked)="selectNewClient()" [color]="'secondary'"
                [content]="addClientCta" [faIcon]="iconAddClient" class="mb-4 mr-auto"></app-button>
    <app-button (btnClicked)="downloadPdfVehiclePickUp()" *ngIf="!isNew && movement.canale === 'OFFICINA'"
                [color]="'download'"
                [content]="'PDF Consegna Veicolo'"
                [faIcon]="iconAddClient" [loading]="loading" class="mb-4 ml-auto mr-2"></app-button>
    <app-button (btnClicked)="downloadPdfVehicleDropOff()" *ngIf="!isNew && movement.stato === 'TERMINATO'"
                [color]="'download'"
                [content]="'PDF Riconsegna Veicolo'"
                [faIcon]="iconAddClient" [loading]="loading" class="mb-4 mr-2"></app-button>
    <app-button (btnClicked)="downloadPdfRentalB2b()" *ngIf="!isNew && movement.canale === 'BACKOFFICE'"
                [color]="'download'"
                [content]="'PDF Noleggio B2B'"
                [faIcon]="iconAddClient" [loading]="loading" class="mb-4 mr-0"></app-button>
  </div>
  <app-rental-movement-form [data]="data.movement" [practiceId]="data.practiceId"
                            [refreshRentalPracticeEvent$]="data.refreshRentalPracticeEvent$"></app-rental-movement-form>
</div>
