import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DynamicFormComponent} from "@components/dynamic-form/dynamic-form.component";
import {Router} from "@angular/router";
import {UserService} from "@services/admin/user.service";
import {PracticesService} from "@services/practices.service";
import {LoggerService} from "@services/logger.service";
import {OptionSelect} from "@/models/option-select.model";
import {UsedVehicleModel} from "@/models/usedVehicleModel";
import {Registry} from "@/registry.service";
import {ToastrService} from "ngx-toastr";
import {Moment} from "moment/moment";
import {UsedVehicleFormSetting} from "@/form-settings/used-vehicle-form-setting";

@Component({
  selector: 'app-exchange-form',
  templateUrl: '../../../../../components/dynamic-form/dynamic-form.component.html',
})
export class ExchangeFormComponent extends DynamicFormComponent implements OnInit {
  @Output() submitClickEvent = new EventEmitter();
  @Input() data: UsedVehicleModel;
  protected brands: OptionSelect[] = [];

  constructor(
    protected router: Router,
    protected userService: UserService,
    protected practiceService: PracticesService,
    protected toastr: ToastrService
  ) {
    super(toastr, userService)

    this.submitButtonVisible = true
    this.primaryButton = 'Salva'
    this.secondaryBottomButton = 'Chiudi'
  }

  async ngOnInit() {
    this.setupMeta()
    this.refreshForm()

    this.practiceService.getBrandsBypracticeType('USATO').subscribe((brands) => {
      this.brands = []

      for (let brand of brands) {
        this.brands.push({
          'key': brand,
          'value': brand.codiceMarca + ' - ' + brand.descrizioneMarca
        })
      }

      this.setupMeta()
    })
  }

  onSubmit() {
    let exchange = <UsedVehicleModel>this.form.getRawValue()

    this.evaluateExchange(exchange)

    this.practiceService.createExchange(exchange).subscribe({
      next: () => {
        this.toastr.success('Permuta salvata correttamente!');

        this.closeModalAndRefresh()
      },
      error: (error) => {
        LoggerService.error('createExchange failed!');
        LoggerService.error(error);

        this.toastr.error(`Errore durante il salvataggio della permuta.`);

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
      complete: () => {
        LoggerService.log('Request done')

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
    })
  }

  setupMeta(): void {
    this.meta = UsedVehicleFormSetting
      .setBrands(this.brands)
      .getMeta()
      .sort((a: any, b: any) => a.order - b.order)
  }

  bottomButtonAction() {
    this.closeModalAndRefresh()
  }

  protected closeModalAndRefresh() {
    if (!this.data.id) {
      Registry.getInstance().get('PracticeDetailsComponent').closeExchangeDialog()
    } else {
      Registry.getInstance().get('DialogDetailExchangeComponent').close()
      Registry.getInstance().get('PracticeDetailsComponent').refreshExchange()
    }
  }

  private evaluateExchange(exchange) {
    exchange.dataPresuntoRitiro = (!exchange.dataPresuntoRitiro || typeof exchange.dataPresuntoRitiro === 'string') ?
      exchange.dataPresuntoRitiro :
      <Moment><unknown>exchange.dataPresuntoRitiro

    exchange.dataRitiro = (!exchange.dataRitiro || typeof exchange.dataRitiro === 'string') ?
      exchange.dataRitiro :
      <Moment><unknown>exchange.dataRitiro

    exchange.scadenzaBollo = (!exchange.scadenzaBollo || typeof exchange.scadenzaBollo === 'string') ?
      exchange.scadenzaBollo :
      <Moment><unknown>exchange.scadenzaBollo

    exchange.scadenzaRevisione = (!exchange.scadenzaRevisione || typeof exchange.scadenzaRevisione === 'string') ?
      exchange.scadenzaRevisione :
      <Moment><unknown>exchange.scadenzaRevisione
  }
}
