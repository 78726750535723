import {TableColumnsSetting} from "@/interfaces/table-setting";
import {of} from "rxjs";
import {map} from "rxjs/operators";
import {RentalPracticeService} from "@services/data-source-tables/rentals/rental-practice.service";
import {RentalPracticeModel} from "@/models/rental-practice.model";

export class RentalVehicleTableSettings {
  public static getColumnsSetting(rentalPracticeService: RentalPracticeService): TableColumnsSetting<keyof RentalPracticeModel> {
    return [
      {
        name: "btnDetails",
        customComponent: true,
        unsortable: true,
      },
      {
        name: "btnDelete",
        customComponent: true,
        unsortable: true,
      },
      {
        name: "id",
        text: 'ID',
        filter: {
          type: "input"
        }
      },
      {
        name: "statoContratto",
        text: 'Stato Contratto',
        filter: {
          type: "select",
          options: of([
            {
              ATTIVO: 'ATTIVO',
            },
            {
              DISATTIVO: 'DISATTIVO'
            }
          ])
        }
      },
      {
        name: "canale",
        text: 'Canale',
        filter: {
          type: "select",
          options: of([
            {
              BACKOFFICE: 'BACKOFFICE',
            },
            {
              OFFICINA: 'OFFICINA'
            }
          ])
        }
      },
      {
        name: "codicePratica",
        text: 'Numero Pratica',
        filter: {
          type: "input"
        }
      },
      {
        name: "targa",
        text: 'Targa',
        filter: {
          type: "input"
        }
      },
      {
        name: "marca",
        text: "marca",
        type: "object",
        propertyToShow: "descrizioneMarca",
        filter: {
          type: "select",
          options: rentalPracticeService.getAllBrands().pipe(map(brands => brands.map(({
                                                                                         id,
                                                                                         codiceMarca,
                                                                                         descrizioneMarca
                                                                                       }) => ({
            [id]: `${codiceMarca} - ${descrizioneMarca}`
          }))))
        }
      },
      {
        name: "modello",
        text: 'Modello',
        filter: {
          type: "input"
        }
      },
      {
        name: "kmContratto",
        text: 'Km Contratto',
        filter: {
          type: "input"
        }
      },
      {
        name: "dataMad",
        type: "date",
        text: 'Data Inizio',
        filter: {
          type: 'dateRange'
        }
      },
      {
        name: "dataScadenza",
        type: "date",
        text: 'Data Scadenza',
        filter: {
          type: "dateRange"
        }
      },
      {
        name: "valoreVeicolo",
        type: "currency",
        text: 'Valore veicolo',
        filter: {
          type: "input"
        }
      },
      {
        name: "canoneTotale",
        type: "currency",
        text: 'Canone Costo',
        filter: {
          type: "input"
        }
      },
      {
        name: "costoEccedenzaKm",
        type: "currency",
        text: 'Costo Eccedenza Km',
        filter: {
          type: "input"
        }
      }
    ];
  }
}
