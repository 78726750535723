<div class="buttons-over-form mb-3">
  <button (click)="onSubmitForm()" *ngIf="submitButtonVisible && submitTopButton" class="btn btn-primary">
    {{ primaryButton }}
  </button>
  <button (click)="secondButtonAction()" *ngIf="secondButton" class="btn btn-primary btn-space ml-3">
    {{ secondButton }}
  </button>
  <button (click)="thirdButtonAction()" *ngIf="thirdButton" class="btn btn-secondary ml-2">
    {{ thirdButton }}
  </button>
</div>
<ng-container *ngIf="form !== null">
  <form [enctype]="encType" [formGroup]="form" autocomplete="off" class="dynamic-form" id="dynamic-form">
    <mat-tab-group *ngIf="tabs.length !== 0" animationDuration="0ms">
      <mat-tab *ngFor="let tab of tabs">
        <ng-template mat-tab-label>
          <fa-icon [icon]="faIcon"></fa-icon>
          {{ tab.title }}
        </ng-template>
        <div *ngIf="tab.meta.length !== 0" class="content">
          <div *ngFor="let obj of tab.meta" class="form-row w-full">
            <app-control *ngFor="let control of obj.rows" [class]="control.class" [control]="control"
                         [form]="form" [id]="control.key" [triggerMethodSubject]="triggerMethodSubject"></app-control>
          </div>
          <div *ngIf="submitButtonVisible" class="form-row">
            <button (click)="onSubmitForm()" class="btn btn-primary" type="submit">{{ primaryButton }}</button>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>

    <div *ngIf="tabs.length === 0 && meta.length !== 0 && meta[0]?.rows">
      <div *ngFor="let obj of meta" class="form-row w-full">
        <app-control *ngFor="let control of obj.rows" [class]="control.class" [control]="control"
                     [form]="form" [triggerMethodSubject]="triggerMethodSubject"></app-control>
      </div>
      <div class="form-row float-right">
        <button (click)="onSubmitForm()" *ngIf="submitButtonVisible && submitBottomButton"
                class="btn btn-primary">{{ primaryButton }}
        </button>
        <button (click)="bottomButtonAction()" *ngIf="secondaryBottomButton"
                class="btn btn-secondary  ml-3">{{ secondaryBottomButton }}
        </button>
      </div>
    </div>
    <!-- Form with sections -->
    <div *ngIf="tabs.length === 0 && meta.length !== 0 && !meta[0]?.rows" class="flex flex-wrap">
      <div *ngFor="let section of meta[0].sections" class="{{ section.class }}">
        <div class="col-md-12 mt-3 mb-2 p-1 text-center title-repeater w-full">
          <p class="m-auto font-bold text-base">{{ section.title }}</p>
        </div>
        <div class="w-full flex flex-wrap">
          <app-control *ngFor="let control of section.rows" [class]="control.class" [control]="control"
                       [form]="form" [triggerMethodSubject]="triggerMethodSubject"></app-control>
        </div>
      </div>
      <div *ngFor="let obj of meta" class="form-row w-full">
        <app-control *ngFor="let control of obj.rows" [class]="control.class" [control]="control"
                     [form]="form" [triggerMethodSubject]="triggerMethodSubject"></app-control>
      </div>
      <div class="form-row float-right">
        <button (click)="onSubmitForm()" *ngIf="submitButtonVisible && submitBottomButton"
                class="btn btn-primary">{{ primaryButton }}
        </button>
        <button (click)="bottomButtonAction()" *ngIf="secondaryBottomButton"
                class="btn btn-secondary  ml-3">{{ secondaryBottomButton }}
        </button>
      </div>
    </div>
  </form>
</ng-container>
