<div class="container-box">
  <div class="flex mt-3">
    <div class="dynamic-form mx-4 my-2 w-3/12">
      <app-date-picker [meta]="datePickerData" [triggerDate$]="triggerDate$"></app-date-picker>
    </div>
    <div class="dynamic-form mx-4 my-2 w-3/12">
      <app-new-dropdown [meta]="brandsMeta"
                        [triggerSelection$]="triggerSelection$"
                        [triggerUpdateComponent$]="triggerUpdateComponentSelectBrand$"
      ></app-new-dropdown>
    </div>
    <div class="dynamic-form mx-4 my-2 w-3/12 text-right align-bottom">
      <app-button
        (btnClicked)="duplicateMonthMS($event)"
        [additionalClass]="'mb-4'"
        [content]="'Copia mese'"
      ></app-button>
    </div>
    <div class="dynamic-form mx-4 my-2 w-3/12 text-right align-bottom">
      <app-button-new-ms [additionalClass]="'marg'"></app-button-new-ms>
    </div>
  </div>
  <div class="flex">
    <div class="mx-4 my-2 w-full">
      <div class="table-container">
        <app-new-table
          (btnDetailsClickEvent)="btnDetailsClick($event)"
          (btnDeleteClickEvent)="btnDeleteClick($event)"
          [dataSource]="dataSource"
          [reloadTrigger$]="reloadTrigger$"
          (customActionSlideToggle)="changeTransmission($event)"
          [triggerDate$]="triggerDate$"
          [triggerSelector$]="triggerSelection$"
          [displayedColumns]="displayedColumns"
          [initialSort]="{active: 'id',direction: 'desc'}"
          [additionalFilters]="{anno: yearDefault, mese: monthDefault, brandId: brandIdDefault}"
        ></app-new-table>
      </div>
  </div>
</div>
