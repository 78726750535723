import {Component} from '@angular/core';
import {ButtonComponent} from "@components/button/button.component";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {Registry} from "@/registry.service";
import {faPenToSquare} from "@fortawesome/free-solid-svg-icons";
import {DialogCommissionComponent} from "@pages/tables/commission/dialog-commission/dialog-commission.component";
import {CommissionsModel} from "@/models/tables/commissions.model";

@Component({
  selector: 'app-button-new-commission',
  templateUrl: '../../button.component.html',
  styleUrls: ['../../button.component.scss'],
})
export class ButtonNewCommissionComponent extends ButtonComponent {
  private dialogOpened: MatDialogRef<any>
  faIcon = faPenToSquare;

  constructor(protected router: Router, protected toastr: ToastrService, private dialog: MatDialog) {
    super(router, toastr);

    this.showIcon = false
    this.additionalClass = 'ml-2'
    this.content = 'Nuova Provvigione'
  }

  /**
   * Open modal new detail page
   */
  public actionClick(): void {
    this.dialogOpened = this.dialog.open(DialogCommissionComponent, {
      data: new CommissionsModel(),
    });

    Registry.getInstance().set('ButtonNewCommissionComponent', this.dialogOpened)
  }

}
