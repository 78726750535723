import {Injectable} from '@angular/core';
import {ApiService} from "@services/api.service";
import {Observable, of} from "rxjs";
import {
  TableBiggerDiscountColumnStructure
} from "@pages/bigger-discount/table-bigger-discount/table-bigger-discount.component";
import {catchError, map, tap} from "rxjs/operators";
import {LoggerService} from "@services/logger.service";
import {MsDiscountModel} from "@/models/tables/ms-discount-model";
import {MsDuplicationDataModel} from "@/models/tables/ms-duplication-data-model";

export interface TableDataApiParams {
  page?: number;
  size?: number;
  year?: number;
  month?: number;
}

@Injectable({
  providedIn: 'root'
})
export class BiggerDiscountService extends ApiService {

  getDashboard(year: number, month: number): Observable<TableBiggerDiscountColumnStructure[]> {
    return this.http.get<TableBiggerDiscountColumnStructure[]>(this.baseUrl + `/biggerDiscounts/simulator/${year}/${month}/`);
  }

  saveBiggerDiscount(msDiscountModel: MsDiscountModel) {
    return this.http.post<MsDiscountModel>(this.baseUrl + '/biggerDiscounts/biggerDiscounts/save', msDiscountModel).pipe(
      tap(response => LoggerService.log(response, "saved ms discount"))
    )
  }

  duplicateBiggerDiscount(msDiscountModel: MsDuplicationDataModel) {
    return this.http.post<MsDuplicationDataModel>(this.baseUrl + '/biggerDiscounts/biggerDiscounts/replace', msDiscountModel).pipe(
      tap(response => LoggerService.log(response, "duplicate Bigger Discount"))
    )
  }

  deleteBiggerDiscount(biggerDiscountId: number) {
    return this.http.delete(this.baseUrl + `/biggerDiscounts/biggerDiscounts/${biggerDiscountId}/`).pipe(map((response: any) => {
      LoggerService.log(response, 'deleteBiggerDiscount')
      return response;
    }));
  }

  getAllBrands(): Observable<any[]> {
    return this.http.get<any>(this.baseUrl + `/biggerDiscounts/biggerDiscounts/brands`).pipe(map((response: any) => {
      return response;
    }));
  }

  getBiggerDiscounts(
    params: TableDataApiParams = {
      page: 1,
      size: 30,
    }
  ) {
    return this.http.get<any>(this.baseUrl + `/biggerDiscounts/biggerDiscounts/page`, {params: {...params}}).pipe(
      map(({content, ...other}) => ({
        ...other,
        content: content
      })),
      catchError(() => of(null))
    );
  }
}
