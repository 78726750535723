import {DeliveryCardboardPractice} from "@/models/tables/delivery-cardboard.models";
import {AbstractControl, ValidationErrors} from "@angular/forms";
import {ControlType} from "@components/dynamic-form/control-type";

/**
 * THIS CLASS USE ANOTHER TYPE OF META AND SHOULD NOT EXTEND FORM SETTINGS
 */
export class DeliveryCardboardFormSetting {

  protected static classToCall: string

  public static getMeta(): Array<any> {
    return [
      {
        "classToUse": this.classToCall,
        key: "id",
        disabled: true,
        "controlType": ControlType.number,
        "label": "",
        "order": 1,
      },
      {
        "classToUse": this.classToCall,
        key: "dataConsegna",
        class: "max-w-[110px]",
        "label": "Data",
        "controlType": "date",
        "order": 1,
        "required": true,
      },
      {
        "classToUse": this.classToCall,
        key: "marca",
        class: "max-w-[110px]",
        disabled: true,
        "label": "Marca",
        "controlType": "textinput",
        "order": 1,
        "required": true,
      },
      {
        "classToUse": this.classToCall,
        key: "progressivoAnnoMeseMarca",
        class: "max-w-[110px]",
        disabled: true,
        "controlType": "textinput",
        "label": "Progressivo AMM",
        "order": 1,
      },
      {
        "classToUse": this.classToCall,
        key: "progressivoAnnoMarca",
        class: "max-w-[110px]",
        disabled: true,
        "controlType": "textinput",
        "label": "Progressivo AM",
        "order": 1,
      },
      {
        "classToUse": this.classToCall,
        key: "targa",
        class: "max-w-[110px]",
        "controlType": "textinput",
        "label": "Targa",
        "order": 1,
      },
      {
        "classToUse": this.classToCall,
        key: "codicePratica",
        class: "max-w-[110px]",
        "controlType": "autocomplete",
        displayFunction: (pratica?: DeliveryCardboardPractice) => pratica?.codicePratica ?? pratica,
        additionalValidators: [
          (control: AbstractControl): ValidationErrors | null => typeof control?.value !== 'object' ? {matchRequired: true} : null
        ],
        options: [],
        "label": "Codice pratica",
        "order": 1,
      },
      {
        "classToUse": this.classToCall,
        "key": 'cliente',
        "controlType": "textinput",
        "label": "Cliente",
        class: "w-full",
        "order": 1,
      },
      {
        "classToUse": this.classToCall,
        key: "venditore",
        class: "w-full",
        disabled: true,
        "controlType": "textinput",
        "label": "Venditore",
        "order": 1,

      },
      {
        "classToUse": this.classToCall,
        key: "destinatarioFattura",
        class: "max-w-[110px]",
        "controlType": "textinput",
        "label": "Dest. Fatt",
        "order": 1,
      },
      {
        "classToUse": this.classToCall,
        key: "consegnaTrasmessa",
        class: "max-w-[110px]",
        "controlType": "textinput",
        "label": "Cons. Trasm.",
        "order": 1,
      },
      {
        "classToUse": this.classToCall,
        key: "noteConsegna",
        class: "w-full",
        "controlType": "textinput",
        "label": "Note",
        "order": 1,
      },
      {
        "classToUse": this.classToCall,
        key: "creator",
        "controlType": "hidden",
        "label": "",
        "order": 1,
      },
      {
        "classToUse": this.classToCall,
        key: "created",
        "controlType": "hidden",
        "label": "",
        "order": 1,
      },
      {
        "classToUse": this.classToCall,
        key: "modifier",
        "controlType": "hidden",
        "label": "",
        "order": 1,
      },
      {
        "classToUse": this.classToCall,
        key: "modified",
        "controlType": "hidden",
        "label": "",
        "order": 1,
      }
    ];
  }
}
