import {OptionSelect} from "@/models/option-select.model";
import {ControlType} from "@components/dynamic-form/control-type";
import {ControlBase} from "@components/dynamic-form/control-base";
import {DropdownControl} from "@components/dynamic-form/dropdown/dropdown-control";
import {DateControl} from "@components/dynamic-form/date-control";

export class UsedVehicleFormSetting {
  private static brands: OptionSelect[] = [];
  private static isNew: boolean = false;
  private static rows: ControlBase[] = [];

  public static setIsNew(isNew: boolean) {
    this.isNew = isNew;

    return this;
  }

  public static setBrands(brands) {
    this.brands = brands;

    return this;
  }

  public static getMeta(): Array<any> {
    this.rows = [];

    if (!this.isNew) {
      this.addRowStato();
    }

    this.addRowTipoUsato();
    this.addRowClient();
    this.addRowMarca();
    this.addRowModello();
    this.addRowTarga();
    this.addRowImporto();
    this.addRowDataPresuntoRitiro();
    this.addRowDataRitiro();
    this.addRowAutorizzato();
    this.addRowSpeseUsato();
    this.addRowChilometriPercorsi();
    this.addRowColore();
    this.addRowTipologiaFatturazione();
    this.addRowIntestato();
    this.addRowDestinazioneUsato();
    this.addRowScadenzaBollo();
    this.addRowScadenzaRevisione();
    this.addRowPresenzaGravamiContratto();
    this.addRowPresenzaGravamiRitiro();
    this.addRowValoreContabileUsato();
    this.addRowEstinzioneFinanziamento();
    this.addRowCodiceFornitore();
    this.addRowSpeseLeggeDini();
    this.addRowSpeseOfficinaInterna();
    this.addRowSpeseCarrozzeria();
    this.addRowSpeseGommista();
    this.addRowSpeseLavaggio();
    this.addRowSpeseGaranzia();
    this.addRowNote();
    this.addRowPratica();
    this.addRowId();

    return [
      {
        "rows": this.rows,
        "order": 1
      },
    ]
  }

  private static addRowClient() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.key = "cliente";
    inputsComponent.label = "Cliente";
    inputsComponent.class = "w-6/12";
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.order = 1;
    inputsComponent.default = "";
    inputsComponent.required = true;


    this.rows.push(inputsComponent);
  }

  private static addRowStato() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.key = "stato";
    inputsComponent.label = "Stato";
    inputsComponent.class = "w-3/12";
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.order = 1;
    inputsComponent.disabled = true;
    inputsComponent.default = null;

    this.rows.push(inputsComponent);
  }

  private static addRowTipoUsato() {
    const inputsComponent = {} as DropdownControl;
    inputsComponent.key = "tipoUsato";
    inputsComponent.label = "Tipo Ritiro";
    inputsComponent.class = "lg:w-3/12 md:w-6/12 xs:w-full additional-border-input";
    inputsComponent.controlType = ControlType.dropdown;
    inputsComponent.order = 1;
    inputsComponent.required = true;
    inputsComponent.disabled = !this.isNew;
    if (this.isNew) {
      inputsComponent.options = [
        {"value": "Aziendale", "key": "AZIENDALE"},
        {"value": "Acquisto diretto VGI", "key": "ACQUISTO_DIRETTO_VGI"},
        {"value": "Acquisto diretto altri canali", "key": "ACQUISTO_DIRETTO_ALTRI_CANALI"}
      ];
    } else {
      inputsComponent.options = [
        {"value": "Ritiro da NUOVO", "key": "RITIRO_DA_NUOVO"},
        {"value": "Ritiro da USATO", "key": "RITIRO_DA_USATO"},
        {"value": "Aziendale", "key": "AZIENDALE"},
        {"value": "Acquisto diretto VGI", "key": "ACQUISTO_DIRETTO_VGI"},
        {"value": "Acquisto diretto altri canali", "key": "ACQUISTO_DIRETTO_ALTRI_CANALI"}
      ];
    }

    this.rows.push(inputsComponent);
  }

  private static addRowMarca() {
    const inputsComponent = {} as DropdownControl;
    inputsComponent.key = "marca";
    inputsComponent.label = "Marca";
    inputsComponent.class = "lg:w-3/12 md:w-6/12 xs:w-full additional-border-input";
    inputsComponent.controlType = ControlType.dropdown;
    inputsComponent.order = 1;
    inputsComponent.required = true;
    inputsComponent.options = this.brands;
    inputsComponent.compareFn = (o1, o2) => o1?.id === o2?.id;

    this.rows.push(inputsComponent);
  }

  private static addRowModello() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.key = "modello";
    inputsComponent.label = "Modello";
    inputsComponent.class = "lg:w-3/12 md:w-6/12 xs:w-full xs:w-full";
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.order = 1;
    inputsComponent.required = true;

    this.rows.push(inputsComponent);
  }

  private static addRowTarga() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.key = "targa";
    inputsComponent.label = "Targa";
    inputsComponent.class = "lg:w-3/12 md:w-6/12 xs:w-full";
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.order = 1;
    inputsComponent.required = true;

    this.rows.push(inputsComponent);
  }

  private static addRowImporto() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.key = "importo";
    inputsComponent.label = "Importo";
    inputsComponent.class = "lg:w-3/12 md:w-6/12 xs:w-full";
    inputsComponent.controlType = ControlType.number;
    inputsComponent.order = 1;

    this.rows.push(inputsComponent);
  }

  private static addRowDataPresuntoRitiro() {
    const inputsComponent = {} as DateControl;
    inputsComponent.key = "dataPresuntoRitiro";
    inputsComponent.label = "Data presunto ritiro";
    inputsComponent.class = "lg:w-3/12 md:w-6/12 xs:w-full";
    inputsComponent.controlType = ControlType.date;
    inputsComponent.order = 1;

    this.rows.push(inputsComponent);
  }

  private static addRowDataRitiro() {
    const inputsComponent = {} as DateControl;
    inputsComponent.key = "dataRitiro";
    inputsComponent.label = "Data ritiro";
    inputsComponent.class = "lg:w-3/12 md:w-6/12 xs:w-full";
    inputsComponent.controlType = ControlType.date;
    inputsComponent.order = 1;

    this.rows.push(inputsComponent);
  }

  private static addRowAutorizzato() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.key = "autorizzato";
    inputsComponent.label = "Autorizzato";
    inputsComponent.class = "lg:w-6/12 md:w-6/12 xs:w-full";
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.order = 1;

    this.rows.push(inputsComponent);
  }

  private static addRowSpeseUsato() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.key = "speseUsato";
    inputsComponent.label = "Spese Usato";
    inputsComponent.class = "md:w-6/12 lg:w-3/12 w-full";
    inputsComponent.controlType = ControlType.slideToggle;
    inputsComponent.order = 1;
    inputsComponent.default = false;

    this.rows.push(inputsComponent);
  }

  private static addRowChilometriPercorsi() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.key = "chilometriPercorsi";
    inputsComponent.label = "Chilometri percorsi";
    inputsComponent.class = "lg:w-3/12 md:w-6/12 xs:w-full";
    inputsComponent.controlType = ControlType.number;
    inputsComponent.order = 1;

    this.rows.push(inputsComponent);
  }

  private static addRowColore() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.key = "colore";
    inputsComponent.label = "Colore";
    inputsComponent.class = "lg:w-6/12 md:w-6/12 xs:w-full";
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.order = 1;

    this.rows.push(inputsComponent);
  }

  private static addRowTipologiaFatturazione() {
    const inputsComponent = {} as DropdownControl;
    inputsComponent.key = "tipologiaFatturazione";
    inputsComponent.label = "Tipologia Fatturazione";
    inputsComponent.class = "lg:w-3/12 md:w-6/12 xs:w-full";
    inputsComponent.controlType = ControlType.dropdown;
    inputsComponent.order = 1;
    inputsComponent.options = [
      {"value": "Privato", "key": "PRIVATO"},
      {"value": "Fattura Esente", "key": "FATTURA_ESENTE"},
      {"value": "Fattura con IVA", "key": "FATTURA_CON_IVA"},
      {"value": "Fattura con IVA Parziale (40-60)", "key": "FATTURA_CON_IVA_PARZIALE"},
    ];

    this.rows.push(inputsComponent);
  }

  private static addRowIntestato() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.key = "intestato";
    inputsComponent.label = "Intestato a";
    inputsComponent.class = "lg:w-6/12 md:w-6/12 xs:w-full";
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.order = 1;

    this.rows.push(inputsComponent);
  }

  private static addRowDestinazioneUsato() {
    const inputsComponent = {} as DropdownControl;
    inputsComponent.key = "destinazioneUsato";
    inputsComponent.label = "Destinazione";
    inputsComponent.class = "lg:w-3/12 md:w-6/12 xs:w-full";
    inputsComponent.controlType = ControlType.dropdown;
    inputsComponent.order = 1;
    inputsComponent.options = [
      {"value": "Permuta", "key": "PERMUTA"},
      {"value": "Rottamazione", "key": "ROTTAMAZIONE"}
    ];

    this.rows.push(inputsComponent);
  }

  private static addRowScadenzaBollo() {
    const inputsComponent = {} as DateControl;
    inputsComponent.key = "scadenzaBollo";
    inputsComponent.label = "Scadenza bollo";
    inputsComponent.class = "lg:w-3/12 md:w-6/12 xs:w-full";
    inputsComponent.controlType = ControlType.date;
    inputsComponent.order = 1;

    this.rows.push(inputsComponent);
  }

  private static addRowScadenzaRevisione() {
    const inputsComponent = {} as DateControl;
    inputsComponent.key = "scadenzaRevisione";
    inputsComponent.label = "Scadenza revisione";
    inputsComponent.class = "lg:w-3/12 md:w-6/12 xs:w-full";
    inputsComponent.controlType = ControlType.date;
    inputsComponent.order = 1;

    this.rows.push(inputsComponent);
  }

  private static addRowPresenzaGravamiContratto() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.key = "presenzaGravamiContratto";
    inputsComponent.label = "Presenza gravami in fase di contratto";
    inputsComponent.class = "md:w-6/12 lg:w-3/12 w-full";
    inputsComponent.controlType = ControlType.slideToggle;
    inputsComponent.order = 1;
    inputsComponent.default = false;

    this.rows.push(inputsComponent);
  }

  private static addRowPresenzaGravamiRitiro() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.key = "presenzaGravamiRitiro";
    inputsComponent.label = "Presenza gravami in fase di ritiro";
    inputsComponent.class = "md:w-6/12 lg:w-3/12 w-full";
    inputsComponent.controlType = ControlType.slideToggle;
    inputsComponent.order = 1;
    inputsComponent.default = false;

    this.rows.push(inputsComponent);
  }

  private static addRowValoreContabileUsato() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.key = "valoreContabileUsato";
    inputsComponent.label = "Valore contabile usato";
    inputsComponent.class = "lg:w-3/12 md:w-6/12 xs:w-full";
    inputsComponent.controlType = ControlType.number;
    inputsComponent.order = 1;

    this.rows.push(inputsComponent);
  }

  private static addRowEstinzioneFinanziamento() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.key = "estinzioneFinanziamento";
    inputsComponent.label = "Estinzione finanziamento (valore usato)";
    inputsComponent.class = "lg:w-3/12 md:w-6/12 xs:w-full";
    inputsComponent.controlType = ControlType.number;
    inputsComponent.order = 1;

    this.rows.push(inputsComponent);
  }

  private static addRowCodiceFornitore() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.key = "codiceFornitore";
    inputsComponent.label = "Codice fornitore";
    inputsComponent.class = "lg:w-3/12 md:w-6/12 xs:w-full";
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.order = 1;

    this.rows.push(inputsComponent);
  }

  private static addRowSpeseLeggeDini() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.key = "speseLeggeDini";
    inputsComponent.label = "Spese legge Dini";
    inputsComponent.class = "lg:w-3/12 md:w-6/12 xs:w-full";
    inputsComponent.controlType = ControlType.number;
    inputsComponent.order = 1;

    this.rows.push(inputsComponent);
  }

  private static addRowSpeseOfficinaInterna() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.key = "speseOfficinaInterna";
    inputsComponent.label = "Spese Officina Interna";
    inputsComponent.class = "lg:w-3/12 md:w-6/12 xs:w-full";
    inputsComponent.controlType = ControlType.number;
    inputsComponent.order = 1;

    this.rows.push(inputsComponent);
  }

  private static addRowSpeseCarrozzeria() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.key = "speseCarrozzeria";
    inputsComponent.label = "Spese carrozzeria";
    inputsComponent.class = "lg:w-3/12 md:w-6/12 xs:w-full";
    inputsComponent.controlType = ControlType.number;
    inputsComponent.order = 1;

    this.rows.push(inputsComponent);
  }

  private static addRowSpeseGommista() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.key = "speseGommista";
    inputsComponent.label = "Spese gommista";
    inputsComponent.class = "lg:w-3/12 md:w-6/12 xs:w-full";
    inputsComponent.controlType = ControlType.number;
    inputsComponent.order = 1;

    this.rows.push(inputsComponent);
  }

  private static addRowSpeseLavaggio() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.key = "speseLavaggio";
    inputsComponent.label = "Spese lavaggio";
    inputsComponent.class = "lg:w-3/12 md:w-6/12 xs:w-full";
    inputsComponent.controlType = ControlType.number;
    inputsComponent.order = 1;

    this.rows.push(inputsComponent);
  }

  private static addRowSpeseGaranzia() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.key = "speseGaranzia";
    inputsComponent.label = "Spese garanzia";
    inputsComponent.class = "lg:w-3/12 md:w-6/12 xs:w-full";
    inputsComponent.controlType = ControlType.number;
    inputsComponent.order = 1;

    this.rows.push(inputsComponent);
  }

  private static addRowNote() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.key = "note";
    inputsComponent.label = "Note";
    inputsComponent.class = "w-full";
    inputsComponent.controlType = ControlType.textArea;
    inputsComponent.order = 1;

    this.rows.push(inputsComponent);
  }

  private static addRowPratica() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.key = "pratica";
    inputsComponent.label = "";
    inputsComponent.controlType = ControlType.hidden;
    inputsComponent.order = 1;

    this.rows.push(inputsComponent);
  }

  private static addRowId() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.key = "id";
    inputsComponent.label = "";
    inputsComponent.controlType = ControlType.hidden;
    inputsComponent.order = 1;

    this.rows.push(inputsComponent);
  }

}
