<div class="container-box mb-4 w-full">
  <h2 class="text-dark text-lg mx-1 my-2" mat-dialog-title>
    <fa-icon [icon]="faFile"></fa-icon>
    Dettaglio Pratica Noleggio - Nr. Pratica {{ data.codicePratica }}
  </h2>
  <table class="ml-5 mb-3 w-full">
    <tr>
      <td>Targa: {{ data.targa ?? '-' }}</td>
      <td>Marca: {{ getMarca() ?? '-' }}</td>
      <td>Modello: {{ data.modello ?? '-' }}</td>
    </tr>
    <tr>
      <td>Stato Contratto: {{ data.statoContratto ?? '-' }}</td>
    </tr>
  </table>
</div>
<div class="container-box">
  <mat-tab-group *ngIf="tabs.length !== 0" animationDuration="0ms">
    <mat-tab *ngFor="let tab of tabs">
      <ng-template mat-tab-label>
                    <span *ngIf="tab.faIcon">
                        <fa-icon [icon]="tab.faIcon"></fa-icon>
                    </span>
        {{ tab.title }}
      </ng-template>
      <div [ngSwitch]="tab.title" class="content">
        <div *ngSwitchCase="'Anagrafica Noleggio VWL'">
          <app-rent-practice-form
            [data]="data"
          ></app-rent-practice-form>
        </div>
        <div *ngSwitchCase="'Noleggio in corso'">
          <div class="w-full flex pr-3">
            <app-button (btnClicked)="downloadPdfVehiclePickUp()"
                        *ngIf="this.data.activeRental && this.data.activeRental.canale === 'OFFICINA'"
                        [color]="'download'"
                        [content]="'PDF Consegna Veicolo'"
                        [faIcon]="iconAddClient" [loading]="loading" class="mb-4"></app-button>
            <app-button (btnClicked)="downloadPdfRentalB2b()"
                        *ngIf="this.data.activeRental && this.data.activeRental.canale === 'BACKOFFICE'"
                        [color]="'download'"
                        [content]="'PDF Noleggio B2B'"
                        [faIcon]="iconAddClient" [loading]="loading" class="mb-4"></app-button>
          </div>
          <app-rent-movement-active-form *ngIf="data?.activeRental"
                                         [data]="data?.activeRental"></app-rent-movement-active-form>
          <div *ngIf="!this.data.activeRental">
            Nessun noleggio in corso!
          </div>
        </div>
        <div *ngSwitchCase="'Movimenti noleggio'">
          <app-rental-movements-list
            [refreshRentalPracticeEvent$]="refreshRentalPracticeEvent$"></app-rental-movements-list>
        </div>
        <div *ngSwitchCase="'Assicurazioni'">
          <app-rental-insurance-list></app-rental-insurance-list>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
