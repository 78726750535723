import {StockService} from "@services/stock.service";
import {of} from "rxjs";
import {map} from "rxjs/operators";
import {getCurrentUserRoles} from "@utils-functions";
import {Role} from "@/models/role";

export class StockTableSettings {

  public static getColumnsSetting(stockService: StockService) {
    let baseData = [
      {
        name: "btnDetails",
        customComponent: true,
        unsortable: true,
      },
      {
        name: "id",
        text: "id",
        filter: {
          type: "input",
        }
      }
    ];

    if (!this.isUserASubAgent()) {
      Array.prototype.push.apply(baseData, [
        {
          name: "visibilitaSubagenti",
          text: "Vis. sub.",
          type: 'slideToggle',
          filter: {
            type: "select",
            options: of([
              {
                true: 'visibile',
              },
              {
                false: 'nascosto'
              }
            ])
          }
        }
      ]);
    }

    Array.prototype.push.apply(baseData, [
      {
        name: "dataScadenzaPrenotazione",
        type: "date",
        text: "Data scad. pren.",
        filter: {
          type: 'dateRange'
        }
      },
      {
        name: "tipoPratica",
        text: "tipo pratica",
        filter: {
          type: "select",
          options: stockService.getPracticeTypes().pipe(map(practiceTypes => practiceTypes.map((practiceType) => ({
            [practiceType]: practiceType
          }))))
        }
      },
      {
        name: "marca",
        text: "marca",
        filter: {
          type: "select",
          options: stockService.getAllBrands().pipe(map(brands => brands.map(({id, codiceMarca, descrizioneMarca}) => ({
            [id]: `${codiceMarca} - ${descrizioneMarca}`
          }))))
        }
      },
      {
        name: "codicePratica",
        text: "N Pratica",
        filter: {
          type: "input"
        }
      },
      {
        name: "targa",
        text: "Targa",
        filter: {
          type: "input"
        }
      },
      {
        name: "telaio",
        text: "Telaio",
        filter: {
          type: "input"
        }
      },
      {
        name: "annoModello",
        text: "Anno mod.",
        filter: {
          type: "input"
        }
      },
      {
        name: "codiceModello",
        text: "Cod. mod.",
        filter: {
          type: "input"
        }
      },
      {
        name: "descrizioneModello",
        text: "Descr. mod.",
        filter: {
          type: "input"
        }
      },
      {
        name: "codiceColore",
        text: "Cod. col.",
        filter: {
          type: "input"
        }
      },
      {
        name: "descrizioneColore",
        text: "Descr. col.",
        filter: {
          type: "input"
        }
      },
      {
        name: "codiceColoreInterno",
        text: "Cod. col. int.",
        filter: {
          type: "input"
        }
      },
      {
        name: "descrizioneColoreInterno",
        text: "Descr. col. int.",
        filter: {
          type: "input"
        }
      },
      {
        name: "dataPrimaImmatricolazione",
        type: "date",
        text: "Data prima immatricolazione",
        filter: {
          type: 'dateRange'
        }
      },
      {
        name: "statoOrdineVgi",
        text: "Stato ordine VGI",
        filter: {
          type: "input"
        }
      },
      {
        name: "settimanaPrevistaProduzione",
        text: "Settimana prevista produzione",
        filter: {
          type: "input"
        }
      },
      {
        name: "equipaggiamenti",
        text: "Equipaggiamenti",
        filter: {
          type: "input"
        }
      }
    ])

    return baseData
  }

  private static isUserASubAgent() {
    return getCurrentUserRoles().includes(Role.Subagents)
      || getCurrentUserRoles().includes(Role.ResponsibleSubagents)
  }
}
