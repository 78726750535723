import {Component} from '@angular/core';
import {LocalDataSource} from 'ng2-smart-table';
import {Registry} from "@/registry.service";
import {CommissionsService} from "@services/data-source-tables/commissions.service";
import {CommissionsSettings} from "@/table-settings/tables/commissions-settings";

@Component({
  selector: 'app-commission',
  templateUrl: './commission.component.html',
  styleUrls: ['./commission.component.scss']
})
export class CommissionComponent {

  source: LocalDataSource;
  settings = CommissionsSettings.gridSettings();

  constructor(private commissionsService: CommissionsService) {
    this.source = new LocalDataSource([]);

    Registry.getInstance().set('CommissionComponent', this)

    this.loadCommissions();
  }

  loadCommissions() {
    this.commissionsService.getAll().subscribe((brands) => {
      this.source.load(brands);
    });
  }
}
