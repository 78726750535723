import {Component} from '@angular/core';
import {faBuildingUser, faCircleInfo, faFile} from "@fortawesome/free-solid-svg-icons";
import {Tab} from "@/interfaces/tab";
import {RentalPracticeModel} from "@/models/rental-practice.model";
import {LoggerService} from "@services/logger.service";
import {RentalPracticeService} from "@services/data-source-tables/rentals/rental-practice.service";
import {ActivatedRoute, Params} from "@angular/router";
import {Subject} from "rxjs";
import {saveAs} from "file-saver";
import {RentalMovementService} from "@services/data-source-tables/rentals/rental-movement.service";

@Component({
  selector: 'app-rent-practice-detail',
  templateUrl: './rent-practice-detail.component.html',
  styleUrls: ['./rent-practice-detail.component.scss']
})
export class RentPracticeDetailComponent {
  data: RentalPracticeModel
  tabs: Array<Tab> = []
  refreshRentalPracticeEvent$ = new Subject<any>();
  protected readonly faFile = faFile;
  protected readonly iconAddClient = faBuildingUser;
  protected loading: boolean = false;
  private id: number;

  constructor(private rentalPracticeService: RentalPracticeService, private route: ActivatedRoute, private rentalMovementService: RentalMovementService) {
    this.route.params.subscribe((params: Params) => {
      this.id = params['id'];
    });
    this.tabs = [
      {
        'title': 'Anagrafica Noleggio VWL',
        'faIcon': faCircleInfo
      },
      {
        'title': 'Noleggio in corso',
        'faIcon': faCircleInfo
      },
      {
        'title': 'Movimenti noleggio',
        'faIcon': faCircleInfo
      },
      {
        'title': 'Assicurazioni',
        'faIcon': faCircleInfo
      },
    ]
    this.data = {} as RentalPracticeModel;

    this.refreshPractice()

    this.refreshRentalPracticeEvent$.subscribe(() => {
      this.refreshPractice()
    });
  }

  async refreshPractice() {
    LoggerService.log('refreshPractice')

    await this.rentalPracticeService.getRentalPractice(this.id).then(practice => {
      LoggerService.log(practice, 'getRentalPractice')

      this.data = <RentalPracticeModel>practice
      this.data.categoriaVeicolo = this.data.marca?.categoriaVeicolo ?? 'ASSENTE'
    });


    return 1;
  }

  getMarca() {
    return this.data.marca?.categoriaVeicolo + ' - ' + this.data.marca?.descrizioneMarca
  }

  downloadPdfVehiclePickUp() {
    this.loading = true

    this.rentalMovementService.downloadPdfVehiclePickUp(this.data.activeRental.id).subscribe({
      next: (res) => {
        saveAs(res, 'ritiro-veicolo-' + this.data.activeRental.id + '.pdf');
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      }
    });
  }

  downloadPdfRentalB2b() {
    this.loading = true

    this.rentalMovementService.downloadPdfRentalB2b(this.data.activeRental.id).subscribe({
      next: (res) => {
        saveAs(res, 'contratto-noleggio-' + this.data.activeRental.id + '.pdf');
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      }
    });
  }
}
