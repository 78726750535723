import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'app-dialog-rental-practices',
  templateUrl: './dialog-rental-practices.component.html',
  styleUrls: ['./dialog-rental-practices.component.scss']
})
export class DialogRentalPracticesComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data) {
  }
}
