import {Component, Input, OnChanges, OnInit} from "@angular/core";
import {Registry} from "@/registry.service";
import {LoggerService} from "@services/logger.service";
import {BiggerDiscountService} from "@services/bigger-discount.service";
import {DynamicFormComponent} from "@components/dynamic-form/dynamic-form.component";
import {UserService} from "@services/admin/user.service";
import {ToastrService} from "ngx-toastr";
import {Subject} from "rxjs";
import {DynamicFormPopUpInterface} from "@components/dynamic-form/dynamic-form-pop-up-interface";
import {MsDuplicationDataModel} from "@/models/tables/ms-duplication-data-model";
import {MSDiscountDuplicateFormSetting} from "@/form-settings/ms-discount/msdiscount-duplicate-form-setting";

@Component({
  selector: 'app-ms-duplicate-form',
  templateUrl: '../../../../../components/dynamic-form/dynamic-form.component.html',
  styleUrls: ['../../../../../components/dynamic-form/dynamic-form.component.scss']
})
export class MsDuplicateFormComponent extends DynamicFormComponent implements OnInit, OnChanges, DynamicFormPopUpInterface {
  @Input() data: MsDuplicationDataModel
  @Input() modalTrigger$ = new Subject<any>();

  constructor(protected userService: UserService, protected toastr: ToastrService, protected biggerDiscountService: BiggerDiscountService) {
    super(toastr, userService)

    this.submitButtonVisible = true
    this.primaryButton = 'Salva'
    this.secondaryBottomButton = 'Chiudi'
  }

  public onSubmit() {
    let rawValue = this.form.getRawValue();
    const duplicationModel = {
      yearFrom: rawValue.yearFrom.year(),
      monthFrom: rawValue.monthFrom.month() + 1,
      monthTo: rawValue.yearMonthTo.month() + 1,
      yearTo: rawValue.yearMonthTo.year(),
      marca: this.data.marca
    } as MsDuplicationDataModel

    this.biggerDiscountService.duplicateBiggerDiscount(duplicationModel).subscribe({
      next: (response) => {
        this.toastr.success('Mese copiato correttamente');

        setTimeout(() => this.modalTrigger$.next(1));
      },
      error: (error) => {
        LoggerService.error('duplicateBiggerDiscount failed!');
        LoggerService.error(error);

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
      complete: () => {
        LoggerService.log('Request done')

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
    })
  }

  ngOnInit(): void {
    this.setupMeta()
  }

  bottomButtonAction() {
    this.modalTrigger$.next(1)
  }

  setupMeta(): void {
    this.meta = MSDiscountDuplicateFormSetting.getMeta()
  }
}
