import {Component} from '@angular/core';
import {DynamicFormComponent} from "@components/dynamic-form/dynamic-form.component";
import {UserService} from "@services/admin/user.service";
import {ToastrService} from "ngx-toastr";
import {
  RentalMovementActiveFormMeta
} from "@pages/rental-management/rent-practice-detail/rental-movement-active-form/rental-practice-form-meta";

@Component({
  selector: 'app-rent-movement-active-form',
  templateUrl: '../../../../components/dynamic-form/dynamic-form.component.html',
  styleUrls: ['../../../../components/dynamic-form/dynamic-form.component.scss']
})
export class RentMovementActiveFormComponent extends DynamicFormComponent {

  constructor(protected userService: UserService, protected toastr: ToastrService) {
    super(toastr, userService)
    this.submitButtonVisible = false
    this.submitBottomButton = false

    this.setupMeta()
  }

  setupMeta(): void {
    this.meta = RentalMovementActiveFormMeta.getMeta()
  }
}
