import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {UsedVehicleModel} from "@/models/usedVehicleModel";

@Component({
  selector: 'app-dialog-new-exchange-form',
  templateUrl: './dialog-new-exchange.component.html',
})
export class DialogNewExchangeComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: UsedVehicleModel) {
  }

}
