import {Component, EventEmitter, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {faBuildingUser} from "@fortawesome/free-solid-svg-icons";
import {
  DialogSelectClientRentComponent
} from "@pages/rental-management/rent-practice-detail/rental-movements-list/dialog-form-rental-movement/dialog-select-client-rent/dialog-select-client-rent.component";
import {RentalClientModel} from "@/models/rentals/rental-client-model";
import {
  RentalMovementFormComponent
} from "@pages/rental-management/rent-practice-detail/rental-movements-list/form/rental-movement-form.component";
import {DynamicFormComponent} from "@components/dynamic-form/dynamic-form.component";
import {
  DataDialogRentalMovement
} from "@pages/rental-management/rent-practice-detail/rental-movements-list/dialog-form-rental-movement/data-dialog-rental-movement";
import {RentalMovementModel} from "@/models/rentals/rental-movement-model";
import {saveAs} from "file-saver";
import {RentalMovementService} from "@services/data-source-tables/rentals/rental-movement.service";

@Component({
  selector: 'app-dialog-form-rental-movement',
  templateUrl: './dialog-form-rental-movement.component.html',
})
export class DialogFormRentalMovementComponent {
  addClientCta: string = 'Seleziona cliente';
  closeSelectClientEvent$: EventEmitter<RentalClientModel> = new EventEmitter<RentalClientModel>();
  @ViewChild(RentalMovementFormComponent) formComponent!: DynamicFormComponent;
  protected iconAddClient = faBuildingUser;
  protected loading: boolean = false;
  protected movement: RentalMovementModel;
  protected isNew: boolean = false;
  private dialog: MatDialogRef<DialogSelectClientRentComponent, any>;
  private rentalMovementService: RentalMovementService;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DataDialogRentalMovement, private dialogRef: MatDialog) {
    this.rentalMovementService = data.rentalMovementService;
    this.movement = data.movement;
    this.isNew = data.isNew;

    this.closeSelectClientEvent$.subscribe((client) => {
      this.data.movement.bpUtilizzatore = client.numeroBp;
      this.data.movement.denominazioneUtilizzatore = client.nome1 === null ? '' : (client.nome1.trim());
      this.data.movement.denominazioneUtilizzatore = this.data.movement.denominazioneUtilizzatore.trim() + ' ';
      this.data.movement.denominazioneUtilizzatore += client.nome2 === null ? '' : (client.nome2.trim() + ' ');
      this.data.movement.denominazioneUtilizzatore = this.data.movement.denominazioneUtilizzatore.trim() + ' ';
      this.data.movement.denominazioneUtilizzatore += client.nome3 === null ? '' : client.nome3.trim();
      this.data.movement.denominazioneUtilizzatore = this.data.movement.denominazioneUtilizzatore.trim();
      this.data.movement.pivaUtilizzatore = client.piva;
      this.data.movement.codiceFiscaleUtilizzatore = client.codiceFiscale;
      this.data.movement.cittaUtilizzatore = client.localita;

      this.formComponent.refreshForm();

      this.dialog.close()
    });
  }

  downloadPdfVehiclePickUp() {
    this.loading = true

    this.rentalMovementService.downloadPdfVehiclePickUp(this.movement.id).subscribe({
      next: (res) => {
        saveAs(res, 'ritiro-veicolo-' + this.movement.id + '.pdf');
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      }
    });
  }

  downloadPdfVehicleDropOff() {
    this.loading = true

    this.rentalMovementService.downloadPdfVehicleDropOff(this.movement.id).subscribe({
      next: (res) => {
        saveAs(res, 'riconsegna-veicolo-' + this.movement.id + '.pdf');
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      }
    });
  }

  downloadPdfRentalB2b() {
    this.loading = true

    this.rentalMovementService.downloadPdfRentalB2b(this.movement.id).subscribe({
      next: (res) => {
        saveAs(res, 'contratto-noleggio-' + this.movement.id + '.pdf');
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      }
    });
  }

  protected selectNewClient() {
    this.dialog = this.dialogRef.open(DialogSelectClientRentComponent, {
      data: {
        service: this.data.rentalClientsService,
        selectEvent: this.closeSelectClientEvent$,
      },
      width: 'auto',
      panelClass: 'custom-modalbox',
    });
  }
}
