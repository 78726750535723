import {Component, Input, OnInit} from "@angular/core";
import {LoggerService} from "@services/logger.service";
import {Registry} from "@/registry.service";
import {Router} from "@angular/router";
import {UserService} from "@services/admin/user.service";
import {ToastrService} from "ngx-toastr";
import {OptionSelect} from "@/models/option-select.model";
import {DynamicFormPopUpInterface} from "@components/dynamic-form/dynamic-form-pop-up-interface";
import {DynamicFormComponent} from "@components/dynamic-form/dynamic-form.component";
import {DynamicFormSubmitInterface} from "@components/dynamic-form/dynamic-form-submit-interface";
import {RentalMovementModel} from "@/models/rentals/rental-movement-model";
import {RentalMovementService} from "@services/data-source-tables/rentals/rental-movement.service";
import {RentalMovementFormSetting} from "@/form-settings/rentals/rental-movement-form-setting";
import {Subject} from "rxjs";

@Component({
  selector: 'app-rental-movement-form',
  templateUrl: '../../../../../components/dynamic-form/dynamic-form.component.html',
  styleUrls: ['../../../../../components/dynamic-form/dynamic-form.component.scss']
})
export class RentalMovementFormComponent extends DynamicFormComponent implements DynamicFormPopUpInterface, DynamicFormSubmitInterface, OnInit {
  @Input() practiceId: number;
  @Input() data: RentalMovementModel
  @Input() refreshRentalPracticeEvent$: Subject<any>;
  protected brands: OptionSelect[] = [];
  private isNew: boolean = true;

  constructor(
    protected toastr: ToastrService,
    protected router: Router,
    protected userService: UserService,
    protected rentalMovementService: RentalMovementService,
  ) {
    super(toastr, userService)
    this.primaryButton = `Salva noleggio`
    this.setupMeta()

    Registry.getInstance().set('RentalMovementFormComponent', this)
  }

  ngOnInit() {
    this.isNew = this.data.id === undefined;

    this.refreshForm()
  }

  setupMeta() {
    this.meta = RentalMovementFormSetting
      .setIsNew(this.isNew)
      .getMeta()
      .sort((a: any, b: any) => a.order - b.order)
  }

  onSubmit() {
    let rentalMovementModel = <RentalMovementModel>this.form.getRawValue()

    this.rentalMovementService.updateRentalMovement(this.practiceId, rentalMovementModel).subscribe({
      next: (response) => {
        this.toastr.success('Noleggio salvato correttamente!');

        this.refreshRentalPracticeEvent$.next()
        this.bottomButtonAction()
      },
      error: (error) => {
        LoggerService.error('updateRentalMovement failed!');
        LoggerService.error(error);
        
        if (error.error.key === 'CONFLICT') {
          this.toastr.error(`Errore! è già presente un noleggio in corso per questa pratica.`);
        } else {
          this.toastr.error(`Errore durante il salvataggio del noleggio.`);
        }

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
      complete: () => {
        LoggerService.log('Request done')

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
    })
  }

  bottomButtonAction(): void {
    Registry.getInstance().get('DialogFormRentalMovementComponent').close()
  }

}
