import {Component, inject, OnInit} from '@angular/core';
import {Subject} from "rxjs";
import {ToastrService} from "ngx-toastr";
import {CashMovementsAnalysisSettings} from "@/table-settings/cash-management/cash-movements-analysis-settings";
import {CashMovementsAnalisisDataSource} from "@components/new-table/data-sources/cash-movements-analisis-data-source";
import {CashMovementsAnalisisService} from "@services/cash-movements.analisis.service";
import {saveAs} from "file-saver";
import {faFileExcel} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-table-ms',
  templateUrl: './cash-movements-analisis.component.html',
  styleUrls: ['./cash-movements-analisis.component.scss']
})
export class CashMovementsAnalisisComponent implements OnInit {
  modalTrigger$ = new Subject<any>();
  triggerDate$ = new Subject<any>();
  triggerSelection$ = new Subject<any>();
  reloadTrigger$ = new Subject<any>();
  displayedColumns = CashMovementsAnalysisSettings.getColumnsSetting();
  dataSource = new CashMovementsAnalisisDataSource(this.cashMovementsAnalysisService);
  toastr = inject(ToastrService);
  columns = [
    {field: 'dataMovimento', header: 'Data Movimento', type: 'date'},
    {field: 'saldoInizialeContanti', header: 'Saldo Iniziale Contanti', type: 'currency'},
    {field: 'saldoInizialeAssegni', header: 'Saldo Iniziale Assegni', type: 'currency'},
    {field: 'sommaEntrataContanti', header: 'Entrata Contanti', type: 'currency'},
    {field: 'sommaEntrataAssegni', header: 'Entrata Assegni', type: 'currency'},
    {field: 'sommaUscitaContanti', header: 'Uscita Contanti', type: 'currency'},
    {field: 'sommaVersamentoContanti', header: 'Versamento Contanti', type: 'currency'},
    {field: 'sommaVersamentoAssegni', header: 'Versamento Assegni', type: 'currency'},
    {field: 'saldoFinaleContanti', header: 'Saldo Finale Contanti', type: 'currency'},
    {field: 'saldoFinaleAssegni', header: 'Saldo Finale Assegni', type: 'currency'},
    {field: 'saldoTotale', header: 'Saldo Totale', type: 'currency'}
  ];
  subColumns = [
    {field: 'dataMovimento', header: 'Data Movimento', type: 'date'},
    {field: 'tipoMovimento', header: 'Tipo Movimento', type: 'string'},
    {field: 'tipoPagamento', header: 'Tipo Pagamento', type: 'string'},
    {field: 'descrizioneMovimento', header: 'Descrizione Movimento', type: 'string'},
    {field: 'codice', header: 'Codice', type: 'string'},
    {field: 'importoAssegni', header: 'Importo Assegni', type: 'currency'},
    {field: 'importoContanti', header: 'Importo Contanti', type: 'currency'},
    {field: 'abbuono', header: 'Abbuono', type: 'string'},
    {field: 'numeroAssegno', header: 'Numero Assegno', type: 'string'},
    {field: 'banca', header: 'Banca', type: 'string'},
    {field: 'creator', header: 'Creator', type: 'string'},
    {field: 'note', header: 'Note', type: 'string'}
  ];
  data = [];

  protected readonly faFileExcel = faFileExcel;

  constructor(private cashMovementsAnalysisService: CashMovementsAnalisisService) {
  }

  async ngOnInit(): Promise<void> {
    await this.cashMovementsAnalysisService.getAnalysisDashboard().then(balancingAccounts => {
      this.data = balancingAccounts;
    });

    this.modalTrigger$.subscribe({
      next: () => {
        this.reloadTrigger$.next(1)
      }
    });
  }

  downloadExcelExport(row: any) {
    this.cashMovementsAnalysisService.downloadExportExcel(row.dataMovimento).subscribe((res) => {
      saveAs(res, 'Export-quadratura' + row.dataMovimento + '.xlsx');
    });
  }
}
