import {ChangeDetectorRef, Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {Subject} from "rxjs";
import {MsDuplicationDataModel} from "@/models/tables/ms-duplication-data-model";

@Component({
  selector: 'app-dialog-ms-duplicate',
  templateUrl: './dialog-ms-duplicate.component.html',
  styleUrls: ['./dialog-ms-duplicate.component.scss']
})
export class DialogMsDuplicateComponent {
  modalTrigger: Subject<any>
  selection: MsDuplicationDataModel

  constructor(@Inject(MAT_DIALOG_DATA) public data, private cdr: ChangeDetectorRef) {
    this.modalTrigger = data.modalTrigger
    this.selection = data.selection
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }
}
