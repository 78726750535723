import {BrandModel} from "@/models/tables/brand.model";
import {RentalMovementModel} from "@/models/rentals/rental-movement-model";

export class RentalPracticeModel {
  id: number;
  activeRental: RentalMovementModel;
  praticaId: any;
  codicePratica: string;
  targa: string;
  marca: BrandModel;
  famiglia: string;
  modello: string;
  statoContratto: string;
  categoriaVeicolo: string;
  kmContratto: number;
  dataMad: string;
  dataScadenza: string;
  canale: string;
  canoneFinanziario: number;
  canoneServizio: number;
  canoneTotale: number;
  percEsuberoKm: number;
  codiceCentroCosto: string;
  descCentroCosto: string;
  tipoGomme: string;
  costoEccedenzaKm: number;
  rimborsoKm: number;
  valoreVeicolo: number;
}
