import {ApiService} from "@services/api.service";
import {TableDataApiParams} from "@services/practices.service";
import {Observable, throwError} from "rxjs";
import {TablePaginationRespModel} from "@/models/table-pagination-resp.model";
import {RentalPracticeModel} from "@/models/rental-practice.model";
import {catchError, map, tap} from "rxjs/operators";
import {Injectable} from "@angular/core";
import {LoggerService} from "@services/logger.service";
import {BrandModel} from "@/models/tables/brand.model";

@Injectable({
  providedIn: 'root'
})
export class RentalPracticeService extends ApiService {

  getDataForTable(params?: TableDataApiParams): Observable<TablePaginationRespModel<RentalPracticeModel>> {
    return new Observable<TablePaginationRespModel<RentalPracticeModel>>(observer => {
      this.http.get<TablePaginationRespModel<RentalPracticeModel>>(
        this.baseUrl + '/rental-practices/page',
        {params: {...params}})
        .pipe(
          tap(response => {
            observer.next(response);
            observer.complete();
          }),
          catchError(error => {
            observer.error(error);

            return throwError(error);
          })
        ).subscribe();
    });
  }

  deleteRentalVehicle(id: number) {
    return this.http.delete(this.baseUrl + `/rental-practices/${id}`)
      .pipe(map((response: any) =>
          tap(() => {
            LoggerService.log(response, 'deletePractice')

            this.triggerUpdate();
            this.toastr.success('Rental practice deleted successfully');
          }),
        catchError(error => {
          this.toastr.error('Failed to delete rental practice');
          return throwError(error);
        })
      ));
  }

  async getRentalPractice(id: number) {
    return this.http.get<RentalPracticeModel>(this.baseUrl + `/rental-practices/${id}/detail`).toPromise();
  }

  updateRentalPractice(rentPractice: RentalPracticeModel) {
    return this.http.post<RentalPracticeModel>(this.baseUrl + '/rental-practices/save/', rentPractice).pipe(map((response: any) => {
      LoggerService.log(response, 'updateRentalPractice')

      return response;
    }));
  }

  createRentalPractice(rentPractice: RentalPracticeModel) {
    return this.http.post<RentalPracticeModel>(this.baseUrl + '/rental-practices/save/', rentPractice).pipe(map((response: any) => {
      LoggerService.log(response, 'createRentalPractice')

      return response;
    }));
  }

  getAllBrands(): Observable<BrandModel[]> {
    return this.http.get<any>(this.baseUrl + `/rental-practices/brands`).pipe(map((response: any) => {
      LoggerService.log(response, 'getBrands')
      return response;
    }));
  }
}
