<div class="container-box">
  <div class="flex mt-3">
    <div class="dynamic-form mx-4 my-2 w-3/12">
      <app-date-picker [meta]="datePickerData" [triggerDate$]="triggerDate$"></app-date-picker>
    </div>
  </div>
  <div class="flex">
    <div class="mx-4 my-2 w-full">
      <app-table-bigger-discount [triggerDate$]="triggerDate$"></app-table-bigger-discount>
    </div>
  </div>
</div>
