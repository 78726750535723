import {TableColumnsSetting} from "@/interfaces/table-setting";
import {RentalMovementModel} from "@/models/rentals/rental-movement-model";
import {of} from "rxjs";

export class RentalMovementTableSettings {

  public static getColumnsSetting(): TableColumnsSetting<keyof RentalMovementModel> {
    return [
      {
        name: "btnDetails",
        customComponent: true,
        unsortable: true,
      },
      {
        name: "id",
        text: 'ID',
        filter: {
          type: "input"
        }
      },
      {
        name: "stato",
        text: 'STATO',
        filter: {
          type: "select",
          options: of([
            {
              IN_CORSO: 'IN_CORSO',
            },
            {
              TERMINATO: 'TERMINATO'
            }
          ])
        }
      },
      {
        name: "canale",
        text: 'Canale',
        filter: {
          type: "select",
          options: of([
            {
              BACKOFFICE: 'BACKOFFICE',
            },
            {
              OFFICINA: 'OFFICINA'
            }
          ])
        }
      },
      {
        name: "dataContratto",
        text: 'Data Contratto',
        type: "date",
        filter: {
          type: "dateRange"
        }
      },
      {
        name: "societa",
        text: 'Società',
        filter: {
          type: "input"
        }
      },
      {
        name: "denominazioneUtilizzatore",
        text: 'Utilizzatore',
        filter: {
          type: "input"
        }
      },
      {
        name: "dataInizioNoleggio",
        text: 'Data inizio noleggio',
        type: "date",
        filter: {
          type: 'dateRange',
        }
      },
      {
        name: "dataFineNoleggio",
        text: 'Data fine noleggio',
        type: "date",
        filter: {
          type: 'dateRange',
        }
      },
      {
        name: "dataConsegna",
        text: 'Data Consegna',
        type: "date",
        filter: {
          type: 'dateRange',
        }
      },
      {
        name: "kmConsegna",
        text: 'Km Consegna'
      },
      {
        name: "dataRientro",
        text: 'Data Rientro',
        type: "date",
        filter: {
          type: 'dateRange',
        }
      },
      {
        name: "kmRientro",
        text: 'Km Rientro'
      },
      {
        name: "prezzo",
        text: 'Prezzo'
      }
    ];
  }
}
