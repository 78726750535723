import {Component, OnInit} from '@angular/core';
import {ButtonComponent} from "@components/button/button.component";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {Registry} from "@/registry.service";
import {faPenToSquare} from "@fortawesome/free-solid-svg-icons";
import {CommissionsModel} from "@/models/tables/commissions.model";
import {DialogCommissionComponent} from "@pages/tables/commission/dialog-commission/dialog-commission.component";

@Component({
  selector: 'app-button-detail-commission',
  templateUrl: '../../button.component.html',
  styleUrls: ['../../button.component.scss'],
})
export class ButtonDetailCommissionComponent extends ButtonComponent implements OnInit {

  faIcon = faPenToSquare;
  private dialogOpened: MatDialogRef<any>
  private commissionsModel: CommissionsModel;
  private rowData: any;

  constructor(protected router: Router, protected toastr: ToastrService, private dialog: MatDialog) {
    super(router, toastr);

    this.showIcon = true
    this.additionalClass = 'py-0.5 px-2.5'
    this.toolTip = 'Dettaglio provvigione'
    this.smallBtn = true;
  }

  ngOnInit() {
    this.commissionsModel = <CommissionsModel>this.rowData;
  }

  /**
   * Open modal new detail page
   */
  public actionClick(): void {
    this.dialogOpened = this.dialog.open(DialogCommissionComponent, {
      data: this.commissionsModel,
    });

    Registry.getInstance().set('ButtonDetailCommissionComponent', this.dialogOpened)
  }
}
