import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormGroup} from "@angular/forms";
import {MatDatepicker} from "@angular/material/datepicker";
import {DateAdapter, MAT_DATE_FORMATS} from "@angular/material/core";
import {InputFieldComponent} from "@components/dynamic-form/input-field.component";
import {Moment} from "moment/moment";
import moment from "moment";
import {MONTH_ONLY_FORMATS} from "@/date-picker-formats";
import {DateControl} from "@components/dynamic-form/date-control";

@Component({
  selector: 'app-month-date-picker-input',
  templateUrl: './month-date-picker-input.component.html',
  styleUrls: ['./month-date-picker-input.component.scss'],
  providers: [
    {provide: MAT_DATE_FORMATS, useValue: MONTH_ONLY_FORMATS},
  ],
})
export class MonthDatePickerInputComponent extends InputFieldComponent implements OnInit {
  @Input() meta!: DateControl
  @Input() form!: FormGroup
  @Input() max: any;
  @Input() indexRow: number = null
  @Input() keyformArrayName: any
  today: Date = new Date();
  protected control: AbstractControl<any>;

  constructor(private dateAdapter: DateAdapter<Date>) {
    super()

    this.today.setDate(this.today.getDate());
  }

  ngOnInit(): void {
    this.setControl()

    const month = this.form.controls[this.meta.key].value - 1;
    this.form.controls[this.meta.key].setValue(moment({month: month}));
  }

  setMonth(normalizedYear: Moment, datepicker: MatDatepicker<Moment>) {
    this.form.controls[this.meta.key].setValue(normalizedYear)

    datepicker.close();
  }
}
