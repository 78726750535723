import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {DataSourceTable} from "@components/new-table/data-sources/data-source-table";
import {RentalClientsTableSettings} from "@/table-settings/rentals/rental-clients-table-settings";

@Component({
  selector: 'app-dialog-select-client-rent',
  templateUrl: './dialog-select-client-rent.component.html',
})
export class DialogSelectClientRentComponent {
  displayedColumns = RentalClientsTableSettings.getColumnsSetting()
  dataSource = new DataSourceTable(this.data.service);


  constructor(@Inject(MAT_DIALOG_DATA) public data) {
  }

  btnSelectClientClickFunction($event: any) {
    console.log('btnDetailsClickFunction', $event);

    this.data.selectEvent.emit($event); // Emit the selected row data
  }
}
