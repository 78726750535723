import {ControlBase} from "@components/dynamic-form/control-base";
import {ControlType} from "@components/dynamic-form/control-type";
import {FormSetting} from "@/form-settings/form-setting";
import {DateControl} from "@components/dynamic-form/date-control";
import {DropdownControl} from "@components/dynamic-form/dropdown/dropdown-control";

export class RentalMovementActiveFormMeta extends FormSetting {
  private static rows: ControlBase[] = [];

  public static getMeta(): Array<any> {
    this.rows = [];

    this.addRowId();
    this.addStato();
    this.addCanale();
    this.addTypeRent();

    //Dati contratto (fase iniziale)
    this.addRowDataContratto();
    this.addRowLuogoContratto();
    this.addRowDataInizioNoleggio();
    this.addRowDataFineNoleggio();
    this.addRowPrezzo();

    this.addRowDataConsegna();
    this.addRowCarburanteConsegna();
    this.addRowKmConsegna();

    //Rientro
    this.addRowDataRientro();
    this.addRowCarburanteRientro();
    this.addRowKmRientro();

    //Dati società
    this.addRowBpSocieta();
    this.addRowSocieta();
    this.addRowIndirizzoSocieta();
    this.addRowCodiceFiscaleSocieta();
    this.addRowPivaSocieta();
    this.addRowEmailSocieta();

    //Dati utilizzatore
    this.addRowBpUtilizzatore();
    this.addRowDenominazioneUtilizzatore();
    this.addRowCittaUtilizzatore();
    this.addRowViaUtilizzatore();
    this.addRowPatenteUtilizzatore();
    this.addRowPatenteRilasciataDaUtilizzatore();
    this.addRowCodiceFiscaleUtilizzatore();
    this.addRowPivaUtilizzatore();
    this.addRowEmailUtilizzatore();

    return [
      {
        "rows": this.rows,
        "order": 1
      },
    ]
  }

  private static addRowId() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'id';
    inputsComponent.disabled = true;
    inputsComponent.controlType = ControlType.textInput
    inputsComponent.label = 'ID';
    inputsComponent.class = "w-4/12";

    this.rows.push(inputsComponent);
  }

  private static addStato() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'stato';
    inputsComponent.disabled = true;
    inputsComponent.controlType = ControlType.textInput
    inputsComponent.label = 'Stato';
    inputsComponent.class = "w-4/12";

    this.rows.push(inputsComponent);
  }

  private static addCanale() {
    const inputsComponent = {} as DropdownControl;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'canale';
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.label = 'Canale';
    inputsComponent.class = "w-4/12";
    inputsComponent.disabled = true;

    this.rows.push(inputsComponent);
  }

  private static addRowCarburanteConsegna() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'carburanteConsegna';
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.label = 'Carburante consegna';
    inputsComponent.class = "w-4/12";
    inputsComponent.disabled = true;

    this.rows.push(inputsComponent);
  }

  private static addRowCarburanteRientro() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'carburanteRientro';
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.label = 'Carburante rientro';
    inputsComponent.class = "w-4/12";
    inputsComponent.disabled = true;

    this.rows.push(inputsComponent);
  }

  private static addRowBpSocieta() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'bpSocieta';
    inputsComponent.controlType = ControlType.number;
    inputsComponent.label = 'BP Societa';
    inputsComponent.class = "w-4/12";
    inputsComponent.required = true;
    inputsComponent.disabled = true;

    this.rows.push(inputsComponent);
  }

  private static addRowSocieta() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'societa';
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.label = 'Societa';
    inputsComponent.class = "w-4/12";
    inputsComponent.required = true;
    inputsComponent.disabled = true;

    this.rows.push(inputsComponent);
  }

  private static addRowIndirizzoSocieta() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'indirizzoSocieta';
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.label = 'Indirizzo societa';
    inputsComponent.class = "w-4/12";
    inputsComponent.disabled = true;

    this.rows.push(inputsComponent);
  }

  private static addRowCodiceFiscaleSocieta() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'codiceFiscaleSocieta';
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.label = 'Codice fiscale societa';
    inputsComponent.class = "w-4/12";
    inputsComponent.disabled = true;

    this.rows.push(inputsComponent);
  }

  private static addRowPivaSocieta() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'pivaSocieta';
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.label = 'PIVA societa';
    inputsComponent.class = "w-4/12";
    inputsComponent.disabled = true;

    this.rows.push(inputsComponent);
  }

  private static addRowEmailSocieta() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'emailSocieta';
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.label = 'Email societa';
    inputsComponent.class = "w-4/12";
    inputsComponent.disabled = true;

    this.rows.push(inputsComponent);
  }

  private static addRowDataInizioNoleggio() {
    const inputsComponent = {} as DateControl;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'dataInizioNoleggio';
    inputsComponent.controlType = ControlType.date;
    inputsComponent.label = 'Data inizio noleggio';
    inputsComponent.class = "w-4/12";
    inputsComponent.disabled = true;

    this.rows.push(inputsComponent);
  }

  private static addRowDataFineNoleggio() {
    const inputsComponent = {} as DateControl;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'dataFineNoleggio';
    inputsComponent.controlType = ControlType.date;
    inputsComponent.label = 'Data fine noleggio';
    inputsComponent.class = "w-4/12";
    inputsComponent.disabled = true;

    this.rows.push(inputsComponent);
  }

  private static addRowDataConsegna() {
    const inputsComponent = {} as DateControl;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'dataConsegna';
    inputsComponent.controlType = ControlType.date;
    inputsComponent.label = 'Data consegna';
    inputsComponent.class = "w-4/12";
    inputsComponent.disabled = true;

    this.rows.push(inputsComponent);
  }

  private static addRowDataContratto() {
    const inputsComponent = {} as DateControl;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'dataContratto';
    inputsComponent.controlType = ControlType.date;
    inputsComponent.label = 'Data contratto';
    inputsComponent.class = "w-4/12";
    inputsComponent.disabled = true;

    this.rows.push(inputsComponent);
  }

  private static addRowDataRientro() {
    const inputsComponent = {} as DateControl;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'dataRientro';
    inputsComponent.controlType = ControlType.date;
    inputsComponent.label = 'Data rientro';
    inputsComponent.class = "w-4/12";
    inputsComponent.disabled = true;

    this.rows.push(inputsComponent);
  }

  private static addRowKmConsegna() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'kmConsegna';
    inputsComponent.controlType = ControlType.number;
    inputsComponent.label = 'Km consegna';
    inputsComponent.class = "w-4/12";
    inputsComponent.disabled = true;

    this.rows.push(inputsComponent);
  }


  private static addRowKmRientro() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'kmRientro';
    inputsComponent.controlType = ControlType.number;
    inputsComponent.label = 'Km rientro';
    inputsComponent.class = "w-4/12";
    inputsComponent.disabled = true;

    this.rows.push(inputsComponent);
  }

  private static addRowLuogoContratto() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'luogoContratto';
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.label = 'Luogo contratto';
    inputsComponent.class = "w-4/12";
    inputsComponent.disabled = true;

    this.rows.push(inputsComponent);
  }

  private static addRowBpUtilizzatore() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'bpUtilizzatore';
    inputsComponent.controlType = ControlType.number;
    inputsComponent.label = 'BP Utilizzatore';
    inputsComponent.class = "w-4/12";
    inputsComponent.required = true;
    inputsComponent.disabled = true;

    this.rows.push(inputsComponent);
  }

  private static addRowDenominazioneUtilizzatore() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'denominazioneUtilizzatore';
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.label = 'Denominazione utilizzatore';
    inputsComponent.class = "w-4/12";
    inputsComponent.required = true;
    inputsComponent.disabled = true;

    this.rows.push(inputsComponent);
  }

  private static addRowCittaUtilizzatore() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'cittaUtilizzatore';
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.label = 'Città utilizzatore';
    inputsComponent.class = "w-4/12";
    inputsComponent.disabled = true;

    this.rows.push(inputsComponent);
  }

  private static addRowViaUtilizzatore() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'viaUtilizzatore';
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.label = 'Via utilizzatore';
    inputsComponent.class = "w-4/12";
    inputsComponent.disabled = true;

    this.rows.push(inputsComponent);
  }

  private static addRowPatenteUtilizzatore() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'patenteUtilizzatore';
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.label = 'Patente utilizzatore';
    inputsComponent.class = "w-4/12";
    inputsComponent.required = true;
    inputsComponent.disabled = true;

    this.rows.push(inputsComponent);
  }

  private static addRowPatenteRilasciataDaUtilizzatore() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'patenteRilasciataDaUtilizzatore';
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.label = 'Patente rilasciata da utilizzatore';
    inputsComponent.class = "w-4/12";
    inputsComponent.disabled = true;

    this.rows.push(inputsComponent);
  }

  private static addRowCodiceFiscaleUtilizzatore() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'codiceFiscaleUtilizzatore';
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.label = 'Codice fiscale utilizzatore';
    inputsComponent.class = "w-4/12";
    inputsComponent.disabled = true;

    this.rows.push(inputsComponent);
  }

  private static addRowPivaUtilizzatore() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'pivaUtilizzatore';
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.label = 'PIVA utilizzatore';
    inputsComponent.class = "w-4/12";
    inputsComponent.disabled = true;

    this.rows.push(inputsComponent);
  }

  private static addRowEmailUtilizzatore() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'emailUtilizzatore';
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.label = 'Email utilizzatore';
    inputsComponent.class = "w-4/12";
    inputsComponent.disabled = true;

    this.rows.push(inputsComponent);
  }

  private static addRowPrezzo() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'prezzo';
    inputsComponent.controlType = ControlType.number;
    inputsComponent.label = 'Prezzo';
    inputsComponent.class = "w-4/12";
    inputsComponent.disabled = true;

    this.rows.push(inputsComponent);
  }

  private static addTypeRent() {
    const inputsComponent = {} as DropdownControl;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'tipoNoleggio';
    inputsComponent.controlType = ControlType.dropdown;
    inputsComponent.label = 'Tipo Noleggio';
    inputsComponent.class = "w-4/12";
    inputsComponent.required = true;
    inputsComponent.options = [
      {key: 'GRATUITO', value: 'GRATUITO'},
      {key: 'PAGAMENTO', value: 'PAGAMENTO'},
    ],
    inputsComponent.disabled = true;

    this.rows.push(inputsComponent);
  }
}
