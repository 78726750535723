import {TableColumnsSetting} from "@/interfaces/table-setting";
import {CrossF7Model} from "@/models/imports/cross-f7-model";

export class CrossF7SettingsTable {

  public static getColumnsSetting(): TableColumnsSetting<keyof CrossF7Model> {
    return [
      {
        name: "id",
        text: "Id",
        filter: {
          type: "input",
        }
      },
      {
        name: "importId",
        text: "Import Id",
        filter: {
          type: "input",
        }
      },
      {
        name: "practiceNumber",
        text: "Num. pratica",
        filter: {
          type: "input",
        }
      },
      {
        name: "clAcquisitionPropertyName",
        text: "Nome cliente",
        filter: {
          type: "input",
        }
      },
      {
        name: "clAcquisitionPropertyLastName",
        text: "Cognome cliente",
        filter: {
          type: "input",
        }
      },
      {
        name: "modelCode",
        text: "Codice modello",
        filter: {
          type: "input",
        }
      },
      {
        name: "salesModelDescription",
        text: "Descrizione modello",
        filter: {
          type: "input",
        }
      },
      {
        name: "actionCodeDelivery",
        text: "Cod.az.GA (Consegna)",
        filter: {
          type: "input",
        }
      },
      {
        name: "weekPrevPrdConf",
        text: "Sett.prev.prd/conf",
        filter: {
          type: "input",
        }
      },
      {
        name: "createdAt",
        type: "datetime",
        text: "creato il",
        filter: {
          type: 'dateRange'
        }
      },
      {
        name: "updatedAt",
        type: "datetime",
        text: "modificato il",
        filter: {
          type: 'dateRange'
        }
      }
    ];
  }
}
