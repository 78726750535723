import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {ApiService} from "@services/api.service";
import {map} from "rxjs/operators";
import {LoggerService} from "@services/logger.service";
import {CommissionsModel} from "@/models/tables/commissions.model";

@Injectable({
  providedIn: 'root'
})
export class CommissionsService extends ApiService {

  getAll(): Observable<any> {
    return this.http.get<any>(this.baseUrl + `/tables/commissionConfigurazions`);
  }

  getSellerTypes(): Observable<any> {
    return this.http.get<any>(this.baseUrl + `/tables/commissionConfigurazions/sellerTypes`);
  }

  deleteCommission(commissionConfigurationId: number) {
    return this.http.delete(this.baseUrl + `/tables/commissionConfigurazions/${commissionConfigurationId}/`).pipe(map((response: any) => {
      LoggerService.log(response, 'deleteCommissionConfiguration')
      return response;
    }));
  }

  saveCommission(commissionConfiguration: CommissionsModel) {
    return this.http.post(this.baseUrl + `/tables/commissionConfigurazions/save`, commissionConfiguration).pipe(map((response: any) => {
      LoggerService.log(response, 'saveCommissionsConfiguration')
      return response;
    }));
  }
}
