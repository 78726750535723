export class CommissionsModel {
  public id: number;
  public tipoVenditore: string;
  public percentualeProvvigione: number;
  public enabled: boolean;
  public creator: string;
  public created: Date;
  public modifier: string;
  public modified: Date;
}
