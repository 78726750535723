import {Component, OnInit} from '@angular/core';
import {DynamicFormComponent} from "@components/dynamic-form/dynamic-form.component";
import {Router} from "@angular/router";
import {UserService} from "@services/admin/user.service";
import {ToastrService} from "ngx-toastr";
import {Registry} from "@/registry.service";
import {LoggerService} from "@services/logger.service";
import {OptionSelect} from "@/models/option-select.model";
import {CommissionsService} from "@services/data-source-tables/commissions.service";
import {CommissionsModel} from "@/models/tables/commissions.model";
import {CommissionsFormSettings} from "@/form-settings/commissions-form-settings";

@Component({
  selector: 'app-commission-form',
  templateUrl: '../../../../../components/dynamic-form/dynamic-form.component.html',
})
export class CommissionFormComponent extends DynamicFormComponent implements OnInit {
  private sellerTypes: OptionSelect[] = [];

  constructor(protected router: Router, protected userService: UserService, protected commissionsService: CommissionsService, protected toastr: ToastrService) {
    super(toastr, userService)

    this.setupMeta()
    this.submitButtonVisible = true
    this.primaryButton = 'Salva'
    this.secondaryBottomButton = 'Chiudi'
  }

  ngOnInit(): void {
    this.form.controls['creator'].setValue(this.userService.getUserName());

    this.commissionsService.getSellerTypes().subscribe(sellerTypes => {
      for (const sellerType of sellerTypes) {
        this.sellerTypes.push({
          key: sellerType,
          value: sellerType
        });
      }

      this.setupMeta()
    });
  }

  onSubmit() {
    let commissionModel = <CommissionsModel>this.form.getRawValue()

    this.commissionsService.saveCommission(commissionModel).subscribe({
      next: () => {
        this.toastr.success('Provvigione salvata correttamente!');

        this.closeModalAndRefresh()
      },
      error: (error) => {
        LoggerService.error('saveCommission failed!');
        LoggerService.error(error);

        this.toastr.error(`Errore durante il salvataggio della provvigione.`);

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
      complete: () => {
        LoggerService.log('Request done')

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
    })
  }

  setupMeta(): void {
    this.meta = CommissionsFormSettings
      .set('sellerTypes', this.sellerTypes)
      .getMeta()
      .sort((a: any, b: any) => a.order - b.order)
  }

  bottomButtonAction() {
    if (this.data.id) {
      Registry.getInstance().get('ButtonDetailCommissionComponent').close()
    } else {
      Registry.getInstance().get('ButtonNewCommissionComponent').close()
    }
  }

  protected closeModalAndRefresh() {
    if (this.data.id) {
      Registry.getInstance().get('ButtonDetailCommissionComponent').close()
    } else {
      Registry.getInstance().get('ButtonNewCommissionComponent').close()
    }

    Registry.getInstance().get('CommissionComponent').loadCommissions();
  }

}
