import {ApiService} from "@services/api.service";
import {TableDataApiParams} from "@services/practices.service";
import {Observable, throwError} from "rxjs";
import {TablePaginationRespModel} from "@/models/table-pagination-resp.model";
import {catchError, map, tap} from "rxjs/operators";
import {LoggerService} from "@services/logger.service";
import {RentalMovementModel} from "@/models/rentals/rental-movement-model";
import {Injectable} from "@angular/core";
import {HttpHeaders} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class RentalMovementService extends ApiService {
  getDataForTable(params?: TableDataApiParams): Observable<TablePaginationRespModel<RentalMovementModel>> {
    const id = params.id;
    delete params.id;

    return new Observable<TablePaginationRespModel<RentalMovementModel>>(observer => {
      this.http.get<TablePaginationRespModel<RentalMovementModel>>(
        this.baseUrl + `/rental-practices/${id}/rentals/page`,
        {params: {...params}})
        .pipe(
          tap(response => {
            observer.next(response);
            observer.complete();
          }),
          catchError(error => {
            console.error('Error occurred:', error.message);
            observer.error(error);

            return throwError(error);
          })
        ).subscribe();
    });
  }

  downloadPdfVehiclePickUp(id: number) {
    return this.http.get(this.baseUrl + `/rentals/${id}/pdf-replacement-vehicle-pick-up`, {
      headers: new HttpHeaders({'Content-Type': 'application/octet-stream'}),
      responseType: 'blob'
    });
  }

  downloadPdfVehicleDropOff(id: number) {
    return this.http.get(this.baseUrl + `/rentals/${id}/pdf-replacement-vehicle-drop-off`, {
      headers: new HttpHeaders({'Content-Type': 'application/octet-stream'}),
      responseType: 'blob'
    });
  }

  downloadPdfRentalB2b(id: number) {
    return this.http.get(this.baseUrl + `/rentals/${id}/pdf-rental-b2b`, {
      headers: new HttpHeaders({'Content-Type': 'application/octet-stream'}),
      responseType: 'blob'
    });
  }

  deleteRentalMovement(id: number) {
    return this.http.delete(this.baseUrl + `/rental-practices/${id}`)
      .pipe(map((response: any) =>
          tap(() => {
            LoggerService.log(response, 'deletePractice')

            this.triggerUpdate();
            this.toastr.success('Rental practice deleted successfully');
          }),
        catchError(error => {
          this.toastr.error('Failed to delete rental practice');
          return throwError(error);
        })
      ));
  }

  async getRentalMovement(id: number) {
    return this.http.get<RentalMovementModel>(this.baseUrl + `/rental-practices/${id}/detail`).toPromise();
  }

  updateRentalMovement(rentalPracticeId: number, rentMovement: RentalMovementModel) {
    return this.http.post<RentalMovementModel>(this.baseUrl + `/rental-practices/${rentalPracticeId}/rentals/save/`, rentMovement).pipe(map((response: any) => {
      LoggerService.log(response, 'updateRentalMovement')

      return response;
    }));
  }
}
