import {FormSetting} from "@/form-settings/form-setting";
import {ControlBase} from "@components/dynamic-form/control-base";
import {ControlType} from "@components/dynamic-form/control-type";
import {DateControl} from "@components/dynamic-form/date-control";

export class MSDiscountDuplicateFormSetting extends FormSetting {
  protected static classToCall: string
  private static rows: ControlBase[] = [];

  public static setClassToCall(classes) {
    this.classToCall = classes

    return this
  }

  public static getMeta(): Array<any> {
    this.rows = [];

    this.addRowMonthYearTo();
    this.addRowYearFrom();
    this.addRowMonthFrom();
    this.addBrand();

    return [
      {
        "rows": this.rows,
        "order": 1
      },
    ]
  }

  private static addBrand() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'marca';
    inputsComponent.label = 'Marca';
    inputsComponent.controlType = ControlType.textInput
    inputsComponent.disabled = true;
    inputsComponent.class = "w-3/12";
    inputsComponent.isObject = true;
    inputsComponent.objectKeys = ['codiceMarca', 'descrizioneMarca'];

    this.rows.push(inputsComponent);
  }

  private static addRowYearFrom() {
    const inputsComponent = {} as DateControl;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'yearFrom';
    inputsComponent.controlType = ControlType.yearDate
    inputsComponent.label = 'Da Anno';
    inputsComponent.class = "w-3/12";
    inputsComponent.disabled = true;

    this.rows.push(inputsComponent);
  }

  private static addRowMonthFrom() {
    const inputsComponent = {} as DateControl;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'monthFrom';
    inputsComponent.controlType = ControlType.monthDate
    inputsComponent.label = 'Da Mese';
    inputsComponent.class = "w-3/12";
    inputsComponent.disabled = true;

    this.rows.push(inputsComponent);
  }

  private static addRowMonthYearTo() {
    const inputsComponent = {} as DateControl;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'yearMonthTo';
    inputsComponent.controlType = ControlType.monthYearDate
    inputsComponent.label = 'Su nuovo mese/anno';
    inputsComponent.class = "w-3/12";
    inputsComponent.maxDate = true;

    this.rows.push(inputsComponent);
  }
}
